import React from 'react'
import {
    Redirect,
    useRouteMatch,
} from 'react-router-dom';


export default function EmailVerificationLinkHandler() {
    const match = useRouteMatch()

    return <Redirect to={{
        pathname: "/email-confirmation/verifying",
        state: match.params
    }} />
}
