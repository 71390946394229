import React, { useRef, useState } from 'react';
// import ReactDOM from 'react-dom';

import ReactDOM from 'react-dom';
import { colors } from '../../../../App.json'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BlankImage from '../../../../assets/images/game-0.png'
import Button from '../../../../utils/Button'

import ModalGameUpload from './ModalGameUpload';

import GameCard from './GameCard';
import Avatar from '../../../../assets/images/avi.png';

function ListingCard(props) {
    const {
        auth,
        self
    } = props

    const cardRef = useRef();
    let game = self.game

    const [ShowUploadGameModal, setShowUploadGameModal] = useState(false)

    const [Buttons, setButtons] = useState({
        rent: {
            text: {
                color: colors.white,
                value: "Rent",
            },
            styles: {
                margin: '0 10px 0 0',
                fontSize: '12px',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white }`,
                borderRadius: '3px',
                color: colors.white
            },
            linkTo: {
                pathname: `/order/${game.slug}/rent`,
                state: { listing:self, game }
            }
        },
        swap: {
            text: {
                color: colors.primary,
                value: "Swap",
            },
            styles: {
                margin: '0 10px 0 0',
                fontSize: '12px',
                backgroundColor: colors.tetiary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            linkTo: {
                pathname: `/order/${game.slug}/swap`,
                state: { listing:self, game }
            }
        },
        buy: {
            text: {
                color: colors.primary,
                value: "Buy",
            },
            styles: {
                margin: '0 10px 0 0',
                fontSize: '12px',
                backgroundColor: colors.grey2,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            linkTo: {
                pathname: `/order/${game.slug}/buy`,
                state: { listing:self, game }
            }
        },
        editListing: {
            text: {
                color: colors.white,
                value: "Edit",
            },
            styles: {
                margin: '2px',
                fontSize: "10px",
                backgroundColor: colors.primary,
                color: colors.white
            },
            onClick: () => {
                const ggparent = ReactDOM.findDOMNode(cardRef.current).parentNode.parentNode.parentNode
                ggparent.style.transform = null
                setShowUploadGameModal(true)
            }
        },
        initiateReturn: {
            text: {
                color: colors.white,
                value: "Initiate Return",
            },
            styles: {
                margin: '2px',
                fontSize: "10px",
                backgroundColor: colors.primary,
                color: colors.white
            },
            onClick: () => { }
        },
    })

    return (
        <div ref={cardRef}>
            <GameCard self={self.game} listing={self}>

                {
                    ShowUploadGameModal ? (
                        <ModalGameUpload hideModal={() => {
                            const ggparent = ReactDOM.findDOMNode(cardRef.current).parentNode.parentNode.parentNode
                            if (ggparent.style.transitionDuration) {
                                ggparent.style.transform = "translate3d(-25%, 0px, 0px)"
                            }
                            setShowUploadGameModal(false)
                        }} listing={self} />
                    ) : null
                }

                {
                    auth.user && auth.user.id == self.owner.id ? (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "5px 0 0" }}>

                            <div style={{ display: 'flex' }}>
                                <div style={styles.attribWrapper}>
                                    Orders
                                    <div style={styles.attrib}>
                                        {self.orders.count}
                                    </div>
                                </div>
                                <div style={styles.attribWrapper}>
                                    Revenue
                                    <div style={styles.attrib}>
                                        ₦{self.orders.earned}
                                    </div>
                                </div>
                            </div>

                            {
                                self.status === "available" ? (
                                    <div style={{ display: 'flex' }}>
                                        {/* {
                                        self.status === "rented" || self.status === "swapped" ? (
                                            <Button {...Buttons.initiateReturn} />
                                        ) : null
                                    } */}

                                        <Button {...Buttons.editListing} />
                                    </div>
                                ) : null
                            }

                        </div>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', margin: "5px 0 0" }}>
                            {
                                self.rent ? (
                                    <Button {...Buttons.rent} />
                                ) : null
                            }

                            {
                                self.swap ? (
                                    <Button {...Buttons.swap} />
                                ) : null
                            }

                            {
                                self.sell ? (
                                    <Button {...Buttons.buy} />
                                ) : null
                            }
                        </div>
                    )
                }

            </GameCard>
        </div>
    )
}


const styles = {
    card: {
        backgroundColor: colors.white,
        padding: '15px',

        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
    },
    title: {
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "22px",
        margin: "5px 0",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    gameCoverImage: {
        height: "200px",
        objectFit: "cover",
        borderRadius: "5px",
    },
    attribWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: colors.primary,
        fontFamily: "Roboto",
        fontSize: "6px",
        marginRight: "10px",
    },
    attrib: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${colors.dark}`,
        fontFamily: "Nunito Sans",
        fontSize: "10px",
        borderRadius: "3px",
        color: colors.grey3,
        minHeight: "30px",
        minWidth: "30px",
        padding: "5px",
    },
    gameStatus: {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center"
        },
        heading: {
            fontFamily: "Nunito Sans",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "8px",
            lineHeight: "8px",
        },
        content: {
            fontFamily: "Nunito Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "10px",
            lineHeight: "12px",
        }
    }
}



const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListingCard)