import React, { useState } from 'react'
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory,
    Redirect
} from "react-router-dom";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { colors } from '../../../../App.json';
import { PostMan } from '../../../../Helpers';

// import AviDefault from '../../../../assets/images/avi.png';
import AviDefault from '../../../../assets/images/user-avatar.png';
import ModalImageUpload from '../../components/main/ModalImageUpload';

import IsDesktop from '../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../utils/breakpoints/IsPhone';

import Button from '../../../../utils/Button';
import Header from '../../components/main/Header';
import Footer from '../../components/main/Footer';
import FormField from '../../../../utils/FormField';

import { ToastContainer, toast } from 'react-toastify';

import MyGames from './components/MyGames'
import SavedGames from './components/SavedGames';
import MyProfile from './components/MyProfile';

import { FaChevronLeft } from 'react-icons/fa';
import { AiOutlineCamera } from 'react-icons/ai';
import { IoMdPerson, IoLogoGameControllerB, IoMdHeart } from 'react-icons/io';


function Index(props) {
    const {
        auth
    } = props

    const history = useHistory()
    const match = useRouteMatch()

    const [redirect, setRedirect] = useState(null)

    const [ProfileImage, setProfileImage] = useState(null)
    const [ShowModalImageUpload, setShowModalImageUpload] = useState(false)

    const [ActiveScreen, setActiveScreen] = useState({
        name: 'myProfile',
        path: '/account/'
    })

    const [HeaderConfig, setHeaderConfig] = useState({
        headerButtons: [
            {
                isProtected: false,
                text: {
                    color: colors.white,
                    value: "Sign in",
                },
                styles: {
                    backgroundColor: colors.primary,
                    border: {
                        width: null,
                        style: null,
                        color: null,
                        radius: null,
                    },
                    color: colors.white
                },
                linkTo: "/login",
            },
        ],
        headerStyles: {
            backgroundColor: colors.black
        }
    })

    const [FooterConfig, setFooterConfig] = useState({
        footerStyles: {
            backgroundColor: colors.black
        }
    })

    const [PageButtons, setPageButtons] = useState({
        myProfile: {
            text: {
                color: colors.grey,
                value: <div><IoMdPerson style={{ margin: '0 2px 0 0' }} /> PROFILE</div>
            },
            styles: {
                height: '50px',
                width: '130px',
                margin: '0 5px',
                backgroundColor: null,
                fontSize: '10px',
                color: colors.white
            },
            linkTo: '/account/'
        },
        savedGames: {
            text: {
                color: colors.grey,
                value: <div><IoMdHeart style={{ margin: '0 2px 0 0' }} /> SAVED GAMES</div>
            },
            styles: {
                height: '50px',
                width: '130px',
                margin: '0 5px',
                backgroundColor: null,
                fontSize: '10px',
                color: colors.white
            },
            linkTo: '/account/saved-games'
        },
        myGames: {
            text: {
                color: colors.grey,
                value: <div><IoLogoGameControllerB style={{ margin: '0 2px 0 0' }} /> MY GAMES</div>
            },
            styles: {
                height: '50px',
                width: '130px',
                margin: '0 5px',
                backgroundColor: null,
                fontSize: '10px',
                color: colors.white
            },
            linkTo: '/account/my-games'
        },

        toggleVerificationForm: {
            text: {
                color: colors.grey,
                value: "Verify My Account",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '30px 0 60px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => ToggleVerificationForm(),
            loader: null,
        },
        attemptVerification: {
            text: {
                color: colors.white,
                value: "Submit",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '30px 0 60px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => AttemptVerification(),
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
    })

    const [VerificationFormData, setVerificationFormData] = useState({
        isVisible: false,
        nin: {
            element: 'input',
            value: '',
            label: true,
            labelText: 'Upload your NIN Number',
            props: {
                name: 'nin_input',
                type: 'text',
                placeholder: 'Enter NIN number',
                required: true
            }
        },
        ninConfirm: {
            element: 'input',
            value: '',
            label: true,
            labelText: 'confirm your NIN',
            props: {
                name: 'nin_input',
                type: 'text',
                placeholder: 'Re-enter NIN number',
                required: true
            }
        },
    })
    

    const GoBack = () => {
        return history.goBack()
    }

    const SetActiveScreen = (config, screenSize = 'mobile') => {
        let newButtonState = PageButtons
        for (let btn in newButtonState) {
            let buttonConfig = newButtonState[btn]
            if (btn === config.name) {
                buttonConfig.text.color = colors.primaryLight
            } else {
                buttonConfig.text.color = colors.grey
            }
        }

        //
        setActiveScreen(config)
        //
        setPageButtons({ ...newButtonState })
    }


    const ToggleVerificationForm = () => {
        return setVerificationFormData({ ...VerificationFormData, isVisible: !VerificationFormData.isVisible })
    }

    const AttemptVerification = async () => {
        // Start Loader
        let newPageButtons = PageButtons
        newPageButtons.attemptVerification.loader.isLoading = true
        await setPageButtons({ ...newPageButtons })
        //
        let payload = {}
        let formPayload = {
            nin: VerificationFormData.nin,
            ninConfirm: VerificationFormData.ninConfirm,
        }
        // Validate Fields
        for (let formField in formPayload) {
            let fieldName = formField
            let fieldData = VerificationFormData[formField]
            if (fieldData.props.required) {
                if (!fieldData.value || fieldData.value == ' ') {
                    // Toast Error Message
                    toast.error(`${fieldData.labelText} field is required!`)
                    // Stop Loader
                    newPageButtons.attemptVerification.loader.isLoading = false
                    return setPageButtons({ ...newPageButtons })
                }
                // Set in formPayload
                payload[fieldName] = fieldData.value
            }
        }
        // Ensure NIN match
        if (payload.nin !== payload.ninConfirm) {
            // Toast Error Message
            toast.error("NIN input don't match.")
            // Stop Loader
            newPageButtons.attemptVerification.loader.isLoading = false
            return setPageButtons({ ...newPageButtons })
        } else {
            delete payload['ninConfirm']
        }
        //
        const responseObject = await PostMan(`account/verification/nin/`, 'POST', payload)
        // Stop Loader
        newPageButtons.attemptVerification.loader.isLoading = false
        await setPageButtons({ ...newPageButtons })

        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            console.log("responseData: ", responseData)
            let userData = responseData.user
            await props.updateUser(userData)
        }
        else if (responseObject.status === 'bad_request') {
            let responseData = responseObject.data
            for (let key in responseData) {
                let fieldErrors = responseData[key]
                fieldErrors.map(errorMessage => {
                    // Toast Error Message
                    toast.error(`${key}: ${errorMessage}`)
                })
            }
        }
        else if (responseObject.status === 'error') {
            // Toast Error Message
            toast.error(responseObject.data.message)
        }
        else {
            console.log("Error")
        }
    }


    const MainContent = (config) => {
        return (
            <div style={styles.container}>

                <div style={styles.bannerWrapper}>
                    <div className="container" style={styles.bannerContent}>

                        <div onClick={() => GoBack()} style={styles.goBack}>
                            <FaChevronLeft size={10} />
                            <span style={{ marginLeft: '7px' }}>Back</span>
                        </div>

                        <div style={{
                            ...styles.profileImage,
                            ...config.profileImage,
                        }}>
                            <img
                                style={{
                                    ...styles.imageWrapper,
                                    ...config.imageWrapper
                                }}
                                src={auth.user && auth.user.image ?
                                    auth.user.image
                                    :
                                    AviDefault
                                }
                            />
                            <div style={{
                                position: 'absolute',
                                ...config.changeImageWraper
                            }}>
                                <label className={"hover-primary"} htmlFor="upload-button" style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }}>
                                    <span style={{ backgroundColor: colors.grey, color: colors.primary, height: '25px', width: '25px', borderRadius: '50%', textAlign: 'center' }}>
                                        <AiOutlineCamera />
                                    </span>

                                    <p style={{ color: colors.grey3, margin: '0 0 0 5px', fontSize: '15px' }}>
                                        Change Avatar
                                    </p>
                                </label>
                                <input type="file" id="upload-button" style={{ display: 'none' }} onChange={(e) => setProfileImage({
                                    preview: URL.createObjectURL(e.target.files[0]),
                                    raw: e.target.files[0]
                                })} />
                            </div>
                        </div>

                        <div style={styles.pageTitle}>
                            Account
                        </div>
                    </div>
                </div>

                <div className="container">
                    
                    <div style={styles.adBannerWrapper} />


                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '35px 0 20px 0' }}>
                        <div style={{ height: '4px', width: '21px', backgroundColor: ActiveScreen.name === 'myProfile' ? colors.primary : colors.grey, margin: '0 5px' }} />
                        <div style={{ height: '4px', width: '21px', backgroundColor: ActiveScreen.name === 'savedGames' ? colors.primary : colors.grey, margin: '0 5px' }} />
                        <div style={{ height: '4px', width: '21px', backgroundColor: ActiveScreen.name === 'myGames' ? colors.primary : colors.grey, margin: '0 5px' }} />
                    </div>

                    <div style={{ display: 'flex', flexFlow: 'row noWrap', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.black, margin: '20px 0' }}>
                        <Button {...PageButtons.myProfile}  {...{
                            styles: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '50px',
                                width: config.navButton.width,
                                margin: config.navButton.margin,
                                backgroundColor: null,
                                fontSize: config.navButton.fontSize,
                                color: colors.white
                            },
                        }} />

                        <Button {...PageButtons.savedGames}  {...{
                            styles: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '50px',
                                width: config.navButton.width,
                                margin: config.navButton.margin,
                                backgroundColor: null,
                                fontSize: config.navButton.fontSize,
                                color: colors.white
                            },
                        }} />

                        <Button {...PageButtons.myGames}  {...{
                            styles: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '50px',
                                width: config.navButton.width,
                                margin: config.navButton.margin,
                                backgroundColor: null,
                                fontSize: config.navButton.fontSize,
                                color: colors.white
                            },
                        }} />
                    </div>

                    <Switch>
                        <Route exact path={`${match.path}`}>
                            <MyProfile setActiveScreen={(config) => SetActiveScreen(config)} />
                        </Route>

                        <Route exact path={`${match.path}/saved-games`}>
                            <SavedGames setActiveScreen={(config) => SetActiveScreen(config)} />
                        </Route>

                        <Route exact path={`${match.path}/my-games`}>
                            <MyGames setActiveScreen={(config) => SetActiveScreen(config)} />
                        </Route>                        
                    </Switch>
                </div>
            </div>
        )
    }


    if (redirect) {
        return <Redirect to={redirect} />
    }


    return (
        <>
            <Header {...props} headerConfig={HeaderConfig} />

            {
                ShowModalImageUpload ? (
                    <ModalImageUpload
                        imageToUpload={ProfileImage}
                        hideModal={() => {
                            setProfileImage(null)
                            setShowModalImageUpload(false)
                        }}
                    />
                ) : null
            }

            <IsDesktop>
                {
                    MainContent({
                        profileImage: {
                            top: '100px',
                            left: '50px',
                        },
                        imageWrapper: {
                            height: '180px',
                            width: '180px',
                        },
                        changeImageWraper: {
                            bottom: '15px',
                            right: '-90px',
                        },
                        navButton: {
                            width: "140px",
                            fontSize: "12px",
                            margin: "0 5px"
                        },
                    })
                }
            </IsDesktop>

            <IsTablet>
                {
                    MainContent({
                        profileImage: {
                            top: '100px',
                            left: '50px',
                        },
                        imageWrapper: {
                            height: '180px',
                            width: '180px',
                        },
                        changeImageWraper: {
                            bottom: '15px',
                            right: '-90px',
                        },
                        navButton: {
                            width: "130px",
                            fontSize: "10px",
                            margin: "0 5px"
                        },
                    })
                }
            </IsTablet>

            <IsPhone>
                {
                    MainContent({
                        profileImage: {
                            top: '170px',
                            left: '20px',
                        },
                        imageWrapper: {
                            height: '130px',
                            width: '130px',
                        },
                        changeImageWraper: {
                            bottom: '15px',
                            right: '-110px',
                        },
                        navButton: {
                            width: "115px",
                            fontSize: "9px",
                            margin: "0"
                        },
                    })
                }
            </IsPhone>

            <Footer {...props} footerConfig={FooterConfig} />
        </>
    )
}

const styles = {
    container: {
        // height: '800px',
        padding: "69px 0 0 0",
        backgroundColor: colors.background,
    },
    bannerWrapper: {
        height: '275px',
        backgroundImage: `url(${require('../../../../assets/images/banner-0.png')})`,
        backgroundColor: colors.primary,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    bannerContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        height: '100%',
        position: 'relative',
    },
    adBannerWrapper: {
        // height: '275px',
        height: '150px',
        margin: '35px 0',
        borderRadius: '10px',
        backgroundImage: `url(${require('../../../../assets/images/bg-5.png')})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    pageTitle: {
        fontSize: '34px',
        color: colors.white,
    },
    profileImage: {
        position: 'absolute',
    },
    imageWrapper: {
        objectFit: 'cover',
        backgroundColor: colors.white,
        border: '2px solid #7F3F98',
        boxSizing: 'border-box',
        borderRadius: '100px',
        position: 'relative',
    },
    goBack: {
        display: 'flex',
        alignItems: 'center',

        position: 'absolute',
        top: '30px',
        left: '50px',

        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        cursor: 'pointer',
    },
    
    referralCode: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '49px',
        textTransform: 'uppercase',
    },
    referralMessage: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        width: "300px",
        color: colors.primary
    }
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        // updateUser
    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)