import React, { useState } from 'react'
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect
} from "react-router-dom";
import { colors } from '../../../../App.json'
import Button from '../../../../utils/Button'

import IsDesktop from '../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../utils/breakpoints/IsPhone';

import { IoIosCart, IoLogoGameControllerB, IoMdHeart } from 'react-icons/io'

import Header from '../../components/main/Header';
import Slider from './components/Slider';
import PopularGames from './components/PopularGames';
import AdBanners from './components/AdBanners';
import FeaturedListings from './components/FeaturedListings';
import Footer from '../../components/main/Footer';


function HomeScreen(props) {
    const [redirect, setRedirect] = useState(null)
    const [HeaderConfig, setHeaderConfig] = useState({
        headerButtons: [
            {
                isProtected: false,
                text: {
                    color: colors.white,
                    value: "Sign in",
                },
                styles: {
                    backgroundColor: colors.primary,
                    border: {
                        width: null,
                        style: null,
                        color: null,
                        radius: null,
                    },
                    color: colors.white
                },
                linkTo: "/login",
            },
        ],
        headerStyles: {
            backgroundColor: colors.black
        }
    })
    const [FooterConfig, setFooterConfig] = useState({
        // footerButtons: [
        //     {
        //         isProtected: false,
        //         text: {
        //             color: colors.white,
        //             value: "Sign in",
        //         },
        //         styles: {
        //             backgroundColor: colors.primary,
        //             border: {
        //                 width: null,
        //                 style: null,
        //                 color: null,
        //                 radius: null,
        //             },
        //             color: colors.white
        //         },
        //         linkTo: "/login",
        //     },
        // ],
        footerStyles: {
            backgroundColor: colors.black
        }
    })

    const [Buttons, setButtons] = useState({
        // myGames: {
        //     text: {
        //         color: colors.grey,
        //         value: <div><IoLogoGameControllerB style={{ margin: '0 2px 0 0' }} /> MY GAMES</div>
        //     },
        //     styles: {
        //         height: '50px',
        //         width: '130px',
        //         margin: '0 5px',
        //         backgroundColor: null,
        //         fontSize: '10px',
        //         color: colors.white
        //     },
        //     linkTo: '/'
        // },
        // savedGames: {
        //     text: {
        //         color: colors.grey,
        //         value: <div><IoMdHeart style={{ margin: '0 2px 0 0' }} /> SAVED GAMES</div>
        //     },
        //     styles: {
        //         height: '50px',
        //         width: '130px',
        //         margin: '0 5px',
        //         backgroundColor: null,
        //         fontSize: '10px',
        //         color: colors.white
        //     },
        //     linkTo: '/saved-games'
        // },
        // myOrders: {
        //     text: {
        //         color: colors.grey,
        //         value: <div><IoIosCart style={{ margin: '0 2px 0 0' }} /> MY ORDERS</div>
        //     },
        //     styles: {
        //         height: '50px',
        //         width: '130px',
        //         margin: '0 5px',
        //         backgroundColor: null,
        //         fontSize: '10px',
        //         color: colors.white
        //     },
        //     linkTo: '/my-orders'
        // },
    })
    
    const MainContent = (config) => {
        return (
            <>
                <Slider />
                
                <div className="container">

                    <AdBanners />

                    <PopularGames />

                    <div style={styles.bannerWrapper} />

                    <FeaturedListings />


                </div>
            </>
        )
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <>

            <Header {...props} headerConfig={HeaderConfig} />

            <div style={styles.container}>
                <IsDesktop>
                    {
                        MainContent({
                            navButton: {
                                width: "140px",
                                fontSize: "12px",
                                margin: "0 5px"
                            },
                        })
                    }
                </IsDesktop>

                <IsTablet>
                    {
                        MainContent({
                            navButton: {
                                width: "130px",
                                fontSize: "10px",
                                margin: "0 5px"
                            },
                        })
                    }
                </IsTablet>

                <IsPhone>
                    {
                        MainContent({
                            navButton: {
                                width: "115px",
                                fontSize: "9px",
                                margin: "0"
                            },
                        })
                    }
                </IsPhone>
            </div>

            <Footer {...props} footerConfig={FooterConfig} />

        </>
    )
}

const styles = {
    container: {
        padding: "69px 0 100px 0",
        backgroundColor: colors.background,
    },
    bannerWrapper: {
        // height: '275px',
        height: '150px',
        margin: '35px 0',
        borderRadius: '10px',
        backgroundImage: `url(${require('../../../../assets/images/bg-5.png')})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
}


export default (HomeScreen)