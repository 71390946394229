import React, { useState } from 'react'
import {
    Redirect,
    useRouteMatch,
} from 'react-router-dom';
import Header from '../../components/main/Header';
import {
    colors
} from '../../../../App.json'
import Button from '../../../../utils/Button';

import IsDesktop from '../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../utils/breakpoints/IsPhone';

import { BsCheckCircle } from 'react-icons/bs'


export default function EmailVerification(props) {
    const match = useRouteMatch()
    const key = match.params.key

    const [HeaderConfig, setHeaderConfig] = useState({ isVisible: false })

    const [PageButtons, setPageButtons] = useState({
        next: {
            text: {
                color: colors.white,
                value: "Next",
            },
            styles: {
                width: '100%',
                margin: '20px 0 10px 0',
                backgroundColor: colors.primary,
                color: colors.white
            },
            linkTo: "/password-reset",
        },
        goHome: {
            text: {
                color: colors.primary,
                value: "Go Home",
            },
            styles: {
                margin: '10px 0',
                color: colors.primary
            },
            linkTo: '/',
        },
    })

    const [FormData, setFormData] = useState({
        password: {
            element: 'input',
            value: '',
            label: true,
            labelText: 'Password',
            props: {
                name: 'password_input',
                type: 'password',
                placeholder: 'Password(minimum of 8 characters)'
            }
        },
        passwordConfirm: {
            element: 'input',
            value: '',
            label: true,
            labelText: 'Password Confirmation',
            props: {
                name: 'password_confirm_input',
                type: 'password',
                placeholder: 'Password confirmation'
            }
        },
    })

    const MainContent = (config) => {

        return (
            <div style={{ ...styles.panelRight, padding: `${config.containerPadding}`, }}>

                <div
                    className="d-flex justify-content-center"
                    style={{ ...styles.heading, fontSize: `${config.headingSize}` }}
                >
                    Email Verification Successful
                </div>

                <div className="d-flex flex-column align-items-center justify-content-center" style={styles.subHeading} >
                    <span>Thank you for confirming your email address</span>
                </div>

                <div className="d-flex justify-content-center" style={{}}>
                    <BsCheckCircle size={150} color={colors.primary} />
                </div>

                <div className="d-flex justify-content-center" style={{ marginTop: '35px'}}>
                    <form>
                        <Button {...PageButtons.goHome} />
                    </form>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Header {...props} headerConfig={HeaderConfig} />

            <IsDesktop>
                <div style={styles.container.desktop}>
                    <div style={{ padding: '0 50px', height: '100%' }}>
                        {
                            MainContent({
                                formMinWidth: '450px',
                                headingSize: '34px',
                                containerPadding: '200px 50px 0',
                            })
                        }
                    </div>

                </div>
            </IsDesktop>

            <IsTablet>
                <div style={styles.container.tablet}>
                    {
                        MainContent({
                            formMinWidth: null,
                            headingSize: '24px',
                            containerPadding: '150px 50px 0',
                        })
                    }
                </div>
            </IsTablet>

            <IsPhone>
                <div style={styles.container.phone}>
                    {
                        MainContent({
                            formMinWidth: '200px',
                            headingSize: '20px',
                            containerPadding: '100px 50px 0',
                        })
                    }
                </div>
            </IsPhone>

        </div>
    )
}


const styles = {
    container: {
        desktop: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        tablet: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 50px',
        },
        phone: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    panelRight: {
        // padding: '200px 50px 0',
        height: '100%',
    },
    heading: {
        // fontFamily: 'Raleway',
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28px',
        color: colors.black,
    },
    subHeading: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        // lineHeight: '24px',
        color: 'rgba(45, 58, 48, 0.5)',

        margin: '20px 0'
    },
    formLabel: {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
    },
    dottedSquare: {
        position: 'fixed',
        right: '50px',
        bottom: '50px',
    },
}

