import React, { useState } from 'react'
import {
    Redirect,
    useRouteMatch,
    useLocation,
} from 'react-router-dom';
import {
    colors
} from '../../../../App.json'
import { 
    PostMan 
} from '../../../../Helpers';
import Header from '../../components/main/Header';
import Button from '../../../../utils/Button';

import IsDesktop from '../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../utils/breakpoints/IsPhone';

import { BiMailSend } from 'react-icons/bi'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function EmailVerification(props) {
    const match = useRouteMatch()

    const [redirect, setRedirect] = useState(null)

    const [HeaderConfig, setHeaderConfig] = useState({ isVisible: false })

    const [PageButtons, setPageButtons] = useState({
        next: {
            text: {
                color: colors.white,
                value: "Next",
            },
            styles: {
                width: '100%',
                margin: '20px 0 10px 0',
                backgroundColor: colors.primary,
                color: colors.white
            },
            linkTo: "/password-reset",
        },
        resendLink: {
            text: {
                color: colors.primary,
                value: "Re-send Link",
            },
            styles: {
                margin: '10px 0 10px 0',
                color: colors.primary
            },
            onClick: () => AttemptResendConfirmationLink(),
            loader: {
                isLoading: false,
                size: 15,
                color: colors.primary,
            },
        },
    })

    const [FormData, setFormData] = useState({
        password: {
            element: 'input',
            value: '',
            label: true,
            labelText: 'Password',
            props: {
                name: 'password_input',
                type: 'password',
                placeholder: 'Password(minimum of 8 characters)'
            }
        },
        passwordConfirm: {
            element: 'input',
            value: '',
            label: true,
            labelText: 'Password Confirmation',
            props: {
                name: 'password_confirm_input',
                type: 'password',
                placeholder: 'Password confirmation'
            }
        },
    })

    const AttemptResendConfirmationLink = async () => {
        // Start Loader
        let newPageButtons = PageButtons
        newPageButtons.resendLink.loader.isLoading = true
        await setPageButtons({ ...newPageButtons })
        //
        const responseObject = await PostMan('account/verification/email/resend/', 'post')
        // Stop Loader
        newPageButtons.resendLink.loader.isLoading = false
        setPageButtons({ ...newPageButtons })
        //
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            // Toast Success Message
            toast.success(responseData.message)
        }
        else {
            // Toast Error Message
            toast.error(responseObject.data.message)
        }
    }

    const MainContent = (config) => {

        return (
            <div style={{ ...styles.panelRight, padding: `${config.containerPadding}`, }}>

                <div
                    className="d-flex justify-content-center"
                    style={{ ...styles.heading, fontSize: `${config.headingSize}` }}
                >
                    Check Your Mail
                </div>

                <div className="d-flex flex-column align-items-center justify-content-center" style={styles.subHeading} >
                    <span>A Link has been sent to your Email.</span>
                    <span>Follow it to confirm your email address</span>
                </div>

                <div className="d-flex justify-content-center" style={{}}>
                    <BiMailSend size={150} color={colors.primary} />
                </div>

                <div style={{ marginTop: '35px', minWidth: `${config.formMinWidth}` }}>
                    <form>
                        <Button {...PageButtons.resendLink} />
                    </form>
                </div>
            </div>
        )
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <>
            <Header {...props} headerConfig={HeaderConfig} />

            <IsDesktop>
                <div style={styles.container.desktop}>
                    <div style={{ padding: '0 50px', height: '100%' }}>
                        {
                            MainContent({
                                formMinWidth: '450px',
                                headingSize: '34px',
                                containerPadding: '200px 50px 0',
                            })
                        }
                    </div>

                </div>
            </IsDesktop>

            <IsTablet>
                <div style={styles.container.tablet}>
                    {
                        MainContent({
                            formMinWidth: null,
                            headingSize: '24px',
                            containerPadding: '150px 50px 0',
                        })
                    }
                </div>
            </IsTablet>

            <IsPhone>
                <div style={styles.container.phone}>
                    {
                        MainContent({
                            formMinWidth: '200px',
                            headingSize: '20px',
                            containerPadding: '100px 50px 0',
                        })
                    }
                </div>
            </IsPhone>


            <ToastContainer />
        </>
    )
}


const styles = {
    container: {
        desktop: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        tablet: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 50px',
        },
        phone: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    panelRight: {
        // padding: '200px 50px 0',
        height: '100%',
    },
    heading: {
        // fontFamily: 'Raleway',
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28px',
        color: colors.black,
    },
    subHeading: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        // lineHeight: '24px',
        color: 'rgba(45, 58, 48, 0.5)',

        margin: '20px 0'
    },
    formLabel: {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
    },
    dottedSquare: {
        position: 'fixed',
        right: '50px',
        bottom: '50px',
    },
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        // updateUser
    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification)