import React from 'react';
import {
    colors
} from '../../../../../App.json';
import Breakpoint from '../../../../../utils/breakpoints/Base';
import IsDesktop from '../../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../../utils/breakpoints/IsPhone';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


export default function Slider() {

    const sliderContent = (config) => {
        return (
            <div className={"container"}
                style={{
                    padding: '100px 50px',
                    textAlign: 'initial',
                }}
            >
                {
                    config.content && config.content.heading ? (
                        <div style={{ ...styles.header, fontSize: `${config.headingSize}`, }}>
                            {config.content.heading || ""}
                        </div>
                    ) : null
                }

                {
                    config.content && config.content.subHeading ? (
                        <div style={{ ...styles.subHeader, fontSize: `${config.subHeadingSize}`, }}>
                            {config.content.subHeading || ""}
                        </div>
                    ) : null
                }
            </div>
        )
    }

    const MainContent = (config) => {
        return (
            <div>
                <Carousel
                    autoPlay={true}
                    showThumbs={false}
                    swipeable={true}
                    stopOnHover={false}
                    infiniteLoop={true}
                    interval={4500}
                    showArrows={false}
                    showStatus={false}
                >
                    <div style={{
                        background: `linear-gradient(91.76deg, rgba(0, 0, 0, 0.7) 0.28%, rgba(0, 0, 0, 0.7) 22.79%, rgba(0, 0, 0, 0) 81.83%), url(${require('../../../../../assets/images/bg-slide1.jpg')})`,
                        ...styles.slide,
                    }}>
                        {
                            sliderContent({
                                ...config,
                                content: {
                                    heading: "Start Making Money",
                                    subHeading: "Off Your Old Video Games"
                                }
                            })
                        }
                    </div>

                    <div style={{
                        background: `linear-gradient(91.76deg, rgba(0, 0, 0, 0.7) 0.28%, rgba(0, 0, 0, 0.7) 22.79%, rgba(0, 0, 0, 0) 81.83%), url(${require('../../../../../assets/images/bg-slide2.jpg')})`,
                        ...styles.slide,
                    }}>
                        {
                            sliderContent({
                                ...config,
                                content: {
                                    heading: "Get The Latest Video Games",
                                    subHeading: "At the lowest rate possible"
                                }
                            })
                        }
                    </div>

                    <div style={{
                        background: `linear-gradient(91.76deg, rgba(0, 0, 0, 0.7) 0.28%, rgba(0, 0, 0, 0.7) 22.79%, rgba(0, 0, 0, 0) 81.83%), url(${require('../../../../../assets/images/bg-slide3.jpg')})`,
                        ...styles.slide,
                    }}>
                        {
                            sliderContent({
                                ...config,
                                content: {
                                    heading: "Rent, Swap and Earn",
                                    subHeading: "Video Games at your own convenience.."
                                }
                            })
                        }
                    </div>

                    {/* <div style={{
                        background: `linear-gradient(91.76deg, rgba(0, 0, 0, 0.7) 0.28%, rgba(0, 0, 0, 0.7) 22.79%, rgba(0, 0, 0, 0) 81.83%), url(${require('../../../../../assets/images/home-slide1.jpg')})`,
                        ...styles.slide,

                    }}></div> */}
                </Carousel>
            </div>
        )
    }

    return (
        <div>
            <IsDesktop>
                {
                    MainContent({
                        headingSize: '50px',
                        subHeadingSize: '22px',
                        icon: {
                            height: '45px',
                            justifyContent: 'flex-start'
                        },
                        wrapperPadding: '0 150px',
                        callToAction: {
                            buttonWidth: '150px',
                            buttonHeight: '50px',
                        },
                        navigation: {
                            flexFlow: 'row nowrap',
                            justifyContent: 'flex-end',
                        },
                    })
                }
            </IsDesktop>

            <IsTablet>
                {
                    MainContent({
                        headingSize: '40px',
                        subHeadingSize: '18px',
                        icon: {
                            height: '35px',
                            justifyContent: 'flex-start'
                        },
                        wrapperPadding: '0 50px',
                        callToAction: {
                            buttonWidth: '120px',
                            buttonHeight: '50px',
                        },
                        navigation: {
                            flexFlow: 'row nowrap',
                            justifyContent: 'flex-end',
                        },
                    })
                }
            </IsTablet>

            <IsPhone>
                {
                    MainContent({
                        headingSize: '30px',
                        subHeadingSize: '14px',
                        icon: {
                            height: '25px',
                            justifyContent: 'flex-end'
                        },
                        wrapperPadding: '0 25px',
                        callToAction: {
                            buttonWidth: '120px',
                            buttonHeight: '50px',
                        },
                        navigation: {
                            flexFlow: 'column',
                            alignItems: 'flex-end',
                        },
                    })
                }
            </IsPhone>
        </div>
    )
}


const styles = {
    slide: {
        height: '400px',
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    header: {
        fontFamily: 'Nunito Sans',
        fontWeight: 800,
        // lineHeight: '68px',
        marginBottom: '20px',
        color: colors.white,
    },
    subHeader: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '30px',
        marginBottom: '40px',
        color: colors.white,
    },
}