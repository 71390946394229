import React, { useEffect, useState } from 'react'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ModalOverlay from '../../../../utils/overlay/ModalOverlay'
import {
    colors,
} from '../../../../App.json'

import IsDesktop from '../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../utils/breakpoints/IsPhone';

import FormField from '../../../../utils/FormField';
import { Link, Redirect } from 'react-router-dom';
import Button from '../../../../utils/Button';
import { PostMan } from '../../../../Helpers';
import SearchableInput from '../../../../utils/SearchableInput';
import {
    updateUser,
} from '../../../../redux/actions/AuthActions'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function UploadGameModal(props) {
    const {
        hideModal,
        listing=null,
        auth,
        updateUser,
    } = props

    const [ShowGameUploadForm, setShowGameUploadForm] = useState(false)

    const [AllGames, setAllGames] = useState([])
    const [FilteredGamesList, setFilteredGamesList] = useState([])
    const [SelectedGame, setSelectedGame] = useState(null)
    const [AddressQueryList, setAddressQueryList] = useState([])
    const [SelectedAddress, setSelectedAddress] = useState(null)
    const [Buttons, setButtons] = useState({
        uploadGame: {
            text: {
                color: colors.white,
                value: listing && "UPDATE" || "UPLOAD",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '20px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => AttemptUploadGame(),
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
        saveAddress: {
            text: {
                color: colors.white,
                value: "Save",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '20px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => AttemptSaveAddress(),
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
        closeModal: {
            text: {
                color: colors.primary,
                value: "close",
            },
            styles: {
                height: '30px',
                width: null,
                margin: null,
                backgroundColor: null,
                color: colors.white
            },
            onClick: () => hideModal(),
            loader: null,
        },
    })

    const [formData, setFormData] = useState({
        game: {
            element: 'input',
            value: '',
            label: true,
            labelText: 'Select a game',
            props: {
                name: 'name_of_game',
                type: 'text',
                placeholder: 'Enter name of game',
                required: true,
                // autoFocus: true
            }
        },
        console: {
            element: 'select',
            data: [
                {
                    value: 0,
                    display: '---'
                },
            ],
            value: '',
            label: true,
            labelText: 'Select Console',
            props: {
                name: 'category_input',
                type: 'select',
                placeholder: null,
                required: true
            }
        },
        condition: {
            element: 'select',
            data: [
                {
                    value: 0,
                    display: '---'
                },
                {
                    value: true,
                    display: 'New'
                },
                {
                    value: false,
                    display: 'Used'
                },
            ],
            value: '',
            label: true,
            labelText: 'Condition',
            props: {
                name: 'condition_input',
                type: 'text',
                placeholder: null,
                required: true
            }
        },
        // escrow: {
        //     element: 'select',
        //     data: [
        //         {
        //             value: 0,
        //             display: '---'
        //         },
        //         {
        //             value: "yes",
        //             display: "Yes, escrow only!"
        //         },
        //         {
        //             value: "maybe",
        //             display: "No, Unless 3rd party insists"
        //         },
        //         {
        //             value: "no",
        //             display: "No, I don't want Escrow"
        //         },
        //     ],
        //     helper_info: {
        //         content: "\
        //             Escrow attacts a higher service fee but at the expense of delivery time. \
        //             Using escrow means your order is first sent to one of our testing hubs \
        //             before finally getting delivered to the 3rd party. \
        //         "
        //     },
        //     value: '',
        //     label: true,
        //     labelText: 'Condition',
        //     props: {
        //         name: 'condition_input',
        //         type: 'text',
        //         placeholder: null,
        //         required: true
        //     }
        // },
        rent: {
            element: 'checkbox',
            checked: false,
            label: true,
            labelText: 'Rent',
            props: {
                name: 'rent_input',
                type: 'checkbox',
            }
        },
        rentAmount: {
            element: 'input',
            value: 0,
            label: true,
            labelText: 'Rent Cost (per week)',
            props: {
                name: 'price_input',
                type: 'number',
                placeholder: 'Enter cost per week',
                required: false,
                min: 0,
                max: 5000
            },
            helper_info: {
                content: "",
                style: {}
            }
        },
        swap: {
            element: 'checkbox',
            checked: false,
            label: true,
            labelText: 'Swap',
            props: {
                name: 'swap_input',
                type: 'checkbox',
            }
        },
        sell: {
            element: 'checkbox',
            checked: false,
            label: true,
            labelText: 'Sell',
            props: {
                name: 'sell_input',
                type: 'checkbox',
            }
        },
        sellAmount: {
            element: 'input',
            value: 0,
            label: true,
            labelText: 'Selling Price',
            props: {
                name: 'price_input',
                type: 'number',
                placeholder: 'Enter selling price',
                required: false
            }
        },
    })

    const [AddressFormData, setAddressFormData] = useState({
        address_line: {
            element: 'input',
            value: '',
            label: true,
            labelText: 'Address Line',
            props: {
                name: 'address_line_input',
                type: 'text',
                placeholder: 'Enter pick-up address',
                required: true,
            }
        },
        country: {
            element: 'select',
            data: [
                {
                    value: 0,
                    display: '---'
                },
            ],
            value: '',
            label: true,
            labelText: 'Country',
            props: {
                name: 'country_input',
                type: 'text',
                placeholder: null,
                required: true
            }
        },
        state: {
            element: 'select',
            data: [
                {
                    value: 0,
                    display: '---'
                },
            ],
            value: '',
            label: true,
            labelText: 'State',
            props: {
                name: 'state_input',
                type: 'text',
                placeholder: null,
                required: true
            }
        },
        // city: {
        //     element: 'select',
        //     data: [
        //         {
        //             value: 0,
        //             display: '---'
        //         },
        //     ],
        //     value: '',
        //     label: true,
        //     labelText: 'City',
        //     props: {
        //         name: 'city_input',
        //         type: 'text',
        //         placeholder: null,
        //         required: true
        //     }
        // },
    })

    const AttemptUploadGame = async() => {
        // Start Loader
        let newButtons = Buttons
        newButtons.uploadGame.loader.isLoading = true
        await setButtons({ ...newButtons })

        let payload = {
            "game": formData.game.value,
            "console": formData.console.value,
            "condition": formData.condition.value,
            "swap": formData.swap.checked,
            "rent": formData.rent.checked,
            "rent_amount": formData.rentAmount.value,
            "sell": formData.sell.checked,
            "sell_amount": formData.sellAmount.value,
        }
        // Validate Fields
        for (let formField in formData) {
            let fieldName = formField
            let fieldData = formData[formField]
            // console.log("required: ", fieldData.props.required)
            if (fieldData.props.required) {
                if (!fieldData.value || fieldData.value == ' ' || fieldData.value == 0) {
                    // Stop Loader
                    newButtons.uploadGame.loader.isLoading = false
                    await setButtons({ ...newButtons })
                    // Toast Error Message
                    toast.error(`${fieldData.labelText} field is required!`)
                    return
                }
            }
        }
        if(SelectedGame && SelectedGame.name !== payload["name"]) {
            // Stop Loader
            newButtons.uploadGame.loader.isLoading = false
            await setButtons({ ...newButtons })
            // Toast Error Message
            toast.error("Select a valid game from list of suggested game(s)")
            return
        }

        let responseObject
        if (listing) {
            responseObject = await PostMan(`listing/${listing.id}/`, 'PATCH', payload)
        } else {
            responseObject = await PostMan(`listing/`, 'POST', payload)
        }

        // Stop Loader
        newButtons.uploadGame.loader.isLoading = false
        await setButtons({ ...newButtons })
        
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            // Toast Success message
            toast.success(responseData.message)
            setTimeout(()=> {
                // Hide modal
                hideModal()
                // Reload Page
                window.location.reload()
            }, 2000)
        }
        else {
            console.log("responseObject: ", responseObject)
        }
    }

    const AttemptSaveAddress = async () => {
        // Start Loader
        let newButtons = Buttons
        newButtons.saveAddress.loader.isLoading = true
        await setButtons({ ...newButtons })
        // Payload
        let address_payload = {}
        for (let fieldName in AddressFormData) {
            let fieldData = AddressFormData[fieldName]
            if (fieldData.props.required) {
                if (!fieldData.value || fieldData.value == ' ') {
                    // Toast Error Message
                    toast.error(`${fieldData.labelText} field is required!`)
                    return
                }
            }
            // Set in address payload
            if (fieldName == "address_line") {
                let addressObj = fieldData.value
                if (typeof addressObj == "string") {
                    // Toast Error Message
                    toast.error("Kindly enter a valid address.")
                    return
                } else {
                    address_payload["address_line"] = addressObj.formatted_address
                    address_payload["place_id"] = addressObj.place_id
                    address_payload["latitude"] = +addressObj.geometry.location.lat.toFixed(6);
                    address_payload["longitude"] = +addressObj.geometry.location.lng.toFixed(6);
                }
            } else {
                address_payload[fieldName] = fieldData.value
            }
        }
        // Update User
        address_payload["user"] = auth.user.id
        // Response Object
        const responseObject = await PostMan(`address/`, 'POST', address_payload)
        // Stop Loader
        newButtons.saveAddress.loader.isLoading = false
        await setButtons({ ...newButtons })
        // Handle Response
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            let userData = responseData.user
            await updateUser(userData)
            await hideModal()
        }
        else {
            console.log("responseObject: ", responseObject)
        }
    }

    const FetchAllGames = async() =>{
        const responseObject = await PostMan(`game/all/`, 'GET')
        if (responseObject.status === 'success') {
            let allGames = responseObject.data
            await setAllGames(allGames)
        }
        else {}
    }

    async function findAddressLine (query) {
        let queryFormattedToLowerCase = String(query).toLocaleLowerCase()
        if (queryFormattedToLowerCase.length === 0) {
            return setAddressQueryList([])
        }
        // const AddressQueryList = 
        const responseObject = await PostMan(`address/find-place/?q=${query}`, 'GET')
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            let addressList = responseData.data.map(address => {
                    address['name'] = address.formatted_address    
                    return address
                }
            )
            console.log("addressList: ", addressList)
            await setAddressQueryList(addressList)
        }
        else {
            setAddressQueryList([])
        }
    }

    function getAmountAfterCommision(amount) {
        if (amount > 0) {
            const commisionValue = 0.12
            return amount - (amount * commisionValue)
        }
        return 0
    }

    function filterGamesList(query) {
        let queryFormattedToLowerCase = String(query).toLocaleLowerCase()
        if (queryFormattedToLowerCase.length === 0) {
            return setFilteredGamesList([])
        }
        let queryset = AllGames.filter(game => {
            let gameNameFormattedToLowerCase = String(game.name).toLowerCase()
            return gameNameFormattedToLowerCase.startsWith(queryFormattedToLowerCase)
        })
        return setFilteredGamesList(queryset)
    }

    const FetchCountryList = async () => {
        const responseObject = await PostMan(`location/countries`, 'GET')
        if (responseObject.status === 'success') {
            let countryData = responseObject.data
            let newAddressFormData = AddressFormData
            countryData.map(country => {
                newAddressFormData.country.data.push({
                    value: country.id,
                    display: country.name,
                })
            })
            // Set Nigeria as Default
            newAddressFormData.country.value = 293
            // Fetch States
            FetchStateList(293)
            // Update AddressFormData in state.
            await setAddressFormData({ ...newAddressFormData })
        }
        else { }
    }

    const FetchStateList = async (countryId=293) => {
        const responseObject = await PostMan(`location/states?country_id=${countryId}`, 'GET')
        if (responseObject.status === 'success') {
            let stateList = responseObject.data
            let newAddressFormData = AddressFormData
            // Clear Old List
            newAddressFormData.state.data = [
                {
                    value: 0,
                    display: '---'
                },
            ]
            //
            stateList.map(state => {
                newAddressFormData.state.data.push({
                    value: state.id,
                    display: `${state.name}`,
                })
            })
            await setAddressFormData({ ...newAddressFormData })
        }
        else { }
    }

    useEffect(()=>{
        // Prefill Listing info if listing exists
        if (listing) {
            let newFormData = formData
            // Set Game
            setSelectedGame(listing.game)
            // Set Consoles
            newFormData.console.data.push({
                value: parseInt(listing.console.id),
                display: listing.console.name,
            })
            // Disable fields
            newFormData.console.props['disabled'] = true
            newFormData.condition.props['disabled'] = true
            //
            newFormData.game.value = listing.game.id
            newFormData.console.value = listing.console.id
            newFormData.condition.value = listing.condition
            newFormData.swap.checked = listing.swap
            newFormData.rent.checked = listing.rent
            newFormData.rentAmount.value = listing.rent_amount
            newFormData.sell.checked = listing.sell
            newFormData.sellAmount.value = listing.sell_amount
            setFormData({ ...newFormData })
        }

        if (auth.user && auth.user.default_address) {
            // Fetch and save games list to state
            FetchAllGames()
            //
            setShowGameUploadForm(true)
        } else {
            // Fetch Countries
            FetchCountryList()
        }

    }, [])


    const GameUploadForm = (config) => {
        if (formData) return (
            <>
                <div style={styles.header} className="text-center"> Upload Game</div>

                <span style={{ display: 'flex', justifyContent: 'flex-end', }}>
                    <Button {...Buttons.closeModal} />
                </span>

                <div className="row">
                    <div className="col-12">

                        <SearchableInput
                            disabled={true}
                            formData={formData}
                            change={(newFormData) => {
                                setFormData({ ...newFormData })
                                // Filter Game List
                                let query = newFormData.game.value
                                filterGamesList(query)
                            }}
                            field={{
                                id: 'game',
                                config: formData.game
                            }}
                            filteredList={FilteredGamesList}
                            setFilteredList={(newFilteredList) => setFilteredGamesList(newFilteredList)}
                            selected={SelectedGame}
                            setSelectedObject={(game) => {
                                if (game) {
                                    setSelectedGame({ ...game })
                                    // Set Game name
                                    let newFormData = formData
                                    newFormData.game.value = game.id

                                    // Set Consoles
                                    game.consoles.map(console_ => {
                                        newFormData.console.data.push({
                                            value: parseInt(console_.id),
                                            display: console_.name,
                                        })
                                    })

                                    setFormData({ ...newFormData })
                                } else {
                                    setSelectedGame(game)
                                }
                            }}
                        />

                    </div>

                    <div className="col-6">
                        <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'console',
                                config: formData.console
                            }}
                        />
                    </div>

                    <div className="col-6">
                        <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'condition',
                                config: formData.condition
                            }}
                        />
                    </div>

                    <div className="col-12">
                        Conditions

                        <div className="row">
                            <div className="col-4">
                                <FormField
                                    formData={formData}
                                    change={(newFormData) => setFormData({ ...newFormData })}
                                    field={{
                                        id: 'swap',
                                        config: formData.swap
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <FormField
                                    formData={formData}
                                    change={(newFormData) => {
                                        if (newFormData.rent.checked) {
                                            newFormData.rentAmount.props.required = true
                                        } else {
                                            newFormData.rentAmount.props.required = false
                                        }
                                        setFormData({ ...newFormData })
                                    }}
                                    field={{
                                        id: 'rent',
                                        config: formData.rent
                                    }}
                                />
                            </div>

                            <div className="col-8">
                                {
                                    formData.rent.checked ? (
                                        <>
                                            <FormField
                                                formData={formData}
                                                change={(newFormData) => setFormData({ ...newFormData })}
                                                field={{
                                                    id: 'rentAmount',
                                                    config: formData.rentAmount
                                                }}
                                            />

                                            <p style={{ fontSize: '10px', marginTop: '-15px' }}>
                                                After commision, you get ₦{getAmountAfterCommision(formData.rentAmount.value)}
                                            </p>
                                        </>
                                    ) : null
                                }
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-4">
                                <FormField
                                    formData={formData}
                                    change={(newFormData) => {
                                        if (newFormData.sell.checked) {
                                            newFormData.sellAmount.props.required = true
                                        } else {
                                            newFormData.sellAmount.props.required = false
                                        }
                                        setFormData({ ...newFormData })
                                    }}
                                    field={{
                                        id: 'sell',
                                        config: formData.sell
                                    }}
                                />
                            </div>

                            <div className="col-8">
                                {
                                    formData.sell.checked ? (
                                        <>
                                            <FormField
                                                formData={formData}
                                                change={(newFormData) => setFormData({ ...newFormData })}
                                                field={{
                                                    id: 'sellAmount',
                                                    config: formData.sellAmount
                                                }}
                                            />

                                            <p style={{ fontSize: '10px', marginTop: '-15px' }}>
                                                After commision, you get ₦{getAmountAfterCommision(formData.sellAmount.value)}
                                            </p>
                                        </>
                                    ) : null
                                }
                            </div>
                        </div>

                    </div>


                    <div className="col-12">
                        <p style={styles.paragrapgh}>
                            {/* All games sent to Velcro would be tested at company HQ before being swapped. Any faulty games would be returned to the
                            user and the delivery fee paid will not be returned. */}

                            Disclaimer! Velcro gaming is in no way liable to damages or issues incurred during the use of our system.
                            We in no way offer any form of Escrow.
                        </p>

                        <Button {...Buttons.uploadGame} />

                        <p style={styles.paragrapgh}>
                            By clicking ‘’Upload’’ you confirm that you have read and agreed to
                            our <Link to="/">terms of use</Link> and <Link to="/">privacy policy.</Link>
                        </p>
                    </div>

                </div>
            </>
        ) 
    }

    const AddressUpdateForm = () => {
        return (
            <>
                <div style={styles.header} className="text-center">
                    Enter a default pick up address
                </div>

                <span style={{ display: 'flex', justifyContent: 'flex-end', }}>
                    <Button {...Buttons.closeModal} />
                </span>

                <div className="row mt-3">
                    <div className="col-12">
                        <p style={styles.paragrapgh}>
                            To upload a game on velcro, You're required to have a default pick-up
                            address.
                        </p>
                    </div>

                    <div className="col-12">
                        <SearchableInput
                            formData={AddressFormData}
                            change={(newFormData) => {
                                setFormData({ ...newFormData })
                                // Query GMAPS for address options
                                let query = newFormData.address_line.value
                                findAddressLine(query)
                            }}
                            field={{
                                id: 'address_line',
                                config: AddressFormData.address_line
                            }}
                            filteredList={AddressQueryList}
                            setFilteredList={(newFilteredList) => setAddressQueryList(newFilteredList)}
                            selected={SelectedAddress}
                            setSelectedObject={(address) => {
                                if (address) {
                                    setSelectedAddress({ ...address })
                                    // Set Address
                                    let newFormData = formData
                                    newFormData.address_line.value = address
                                    setFormData({ ...newFormData })
                                } else {
                                    setSelectedAddress(address)
                                }
                            }}
                        />
                    </div>

                    <div className="col-12">
                        <FormField
                            formData={AddressFormData}
                            // change={(newFormData) => setAddressFormData({ ...newFormData })}
                            change={(newAddressFormData) => {
                                let countryId = newAddressFormData.country.value
                                // Update Form
                                setAddressFormData({ ...newAddressFormData })
                                // Fetch States
                                FetchStateList(countryId)
                            }}
                            field={{
                                id: 'country',
                                config: AddressFormData.country
                            }}
                        />
                    </div>

                    <div className="col-12">
                        <FormField
                            formData={AddressFormData}
                            change={(newAddressFormData) => setAddressFormData({ ...newAddressFormData }) }
                            field={{
                                id: 'state',
                                config: AddressFormData.state
                            }}
                        />
                    </div>

                    <div className="col-12">
                        <p style={styles.paragrapgh}>
                            Note that this can always be updated in your account settings.
                        </p>

                        <Button {...Buttons.saveAddress} />
                    </div>
                </div>
            </>
        )
    }

    const MainContent = () => {
        if (ShowGameUploadForm) 
            return GameUploadForm()
        else 
            return AddressUpdateForm()
    }


    return (
        <ModalOverlay>
            <div style={styles.wrapper}>
                

                <IsDesktop>
                    <div style={styles.container}>

                        {
                            MainContent()
                        }
                    </div>
                </IsDesktop>

                <IsTablet>
                    <div style={styles.container}>
                        {
                            MainContent()
                        }
                    </div>
                </IsTablet>

                <IsPhone>
                    <div style={{
                        ...styles.container,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '-webkit-fill-available',
                        minHeight: '-webkit-fill-available',
                    }}>
                        {
                            MainContent()
                        }
                    </div>
                </IsPhone>
            </div>

            <ToastContainer />

        </ModalOverlay>
    )
}


const styles = {
    wrapper: { // Centered Content
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        width: "100%",
        height: "100%",
        position: 'relative',
    },

    container: {
        backgroundColor: colors.white,
        width: "450px",
        padding: "20px 30px",
    },

    header: {
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "17px",
        lineHeight: "24px",
        color: colors.black,
        margin: "20px"
    },
    paragrapgh: {
        fontSize: "14px",
        textAlign: "justify"
    },
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        updateUser
    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadGameModal)