import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
    Route,
    Switch,
    useRouteMatch
} from 'react-router-dom'
import { bindActionCreators } from 'redux'

import GameList from '../../components/GameList'
import GameDetail from '../../components/GameDetail'


function GamesScreen(props) {
    const {
        setActiveScreen
    } = props

    const match = useRouteMatch()

    useEffect(() => {
        setActiveScreen({
            name: 'games',
            path: '/games'
        })

    }, [])

    return (
        <Switch>
            <Route exact path={`${match.path}/:gameSlug`}>
                <GameDetail />
            </Route>

            <Route exact path={`${match.path}`}>
                <GameList />
            </Route>
        </Switch>
    )
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GamesScreen)