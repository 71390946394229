import React, { useState, useEffect } from 'react';

import {
    PostMan
} from '../../../../../Helpers';
import GameCard from '../../../components/main/GameCard';

import IsDesktop from '../../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../../utils/breakpoints/IsPhone';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function PopularGames() {
    const [CategoryGames, setCategoryGames] = useState(null)

    const FetchPopularGames = async () => {
        // const responseObject = await PostMan(`game/all/?category=action`, 'GET')
        const responseObject = await PostMan(`game/popular/`, 'GET')
        if (responseObject.status === 'success') {
            let categoryGames = responseObject.data
            // Save Games to state
            await setCategoryGames(categoryGames)
        }

        else { }
    }

    const MainContent = (config) => {
        return (
            <Carousel
                autoPlay={true}
                showThumbs={false}
                swipeable={true}
                stopOnHover={true}
                infiniteLoop={false}
                interval={2500}
                showArrows={true}
                showStatus={false}
                showIndicators={false}
                {...config.carousel}
            >
                {
                    CategoryGames && CategoryGames.map((game, index) => {
                        return (
                            <div key={index} style={{ width: "95%" }}>
                                <GameCard
                                    self={game}
                                />
                            </div>
                        )
                    })
                }
            </Carousel>
        )
    }


    useEffect(() => {
        // Fetch Category Games
        FetchPopularGames()
    }, [])

    return (
        <div style={{}}>
            <div style={{
                fontSize: "25px",
                fontWeight: 600,
                marginBottom: "10px"
            }}>
                Popular Games
            </div>
            
            <IsDesktop>
                {
                    MainContent({
                        carousel: {
                            centerSlidePercentage: 25,
                            centerMode: true
                        }
                    })
                }
            </IsDesktop>

            <IsTablet>
                {
                    MainContent({
                        carousel: {
                            centerSlidePercentage: 65,
                            centerMode: true
                        }
                    })
                }
            </IsTablet>

            <IsPhone>
                {
                    MainContent({
                        carousel: {
                            centerSlidePercentage: 75,
                            centerMode: true
                        }
                    })
                }
            </IsPhone>
            
        </div>
    )
}
