import React, { Component } from 'react'
import {
    colors
} from '../App.json'
import {
    Link
} from 'react-router-dom'

import HashLoader from "react-spinners/HashLoader"


export default class Button extends Component {
    constructor(props) {
        super(props)
        
    }
    
    render() {
        const buttonState = this.props

        const RenderButton = () => {
            if (buttonState.linkTo) {
                return (
                    <Link
                        className={"btn"}
                        style={{
                            ...buttonState.styles,
                        }}
                        to={buttonState.linkTo}
                    >
                        <span className="hover-primary" style={{ color: buttonState.text.color }}>
                            {buttonState.text.value}
                        </span>
                    </Link>
                )
            } else if (buttonState.onClick) {
                return (
                    <div className={"btn"}
                        style={{
                            ...styles.wrapper,
                            ...buttonState.styles,
                        }}
                        onClick={buttonState.onClick}
                    >
                        <span className="hover-primary" style={{ color: buttonState.text.color }}>
                            {buttonState.text.value}
                        </span>

                        {
                            buttonState.loader ? (
                                <span className="ml-4">
                                    <HashLoader
                                        color={buttonState.loader.color}
                                        loading={buttonState.loader.isLoading}
                                        size={buttonState.loader.size}
                                    />
                                </span>
                            ) : null
                        }

                    </div>
                )
            } else {
                return (
                    <div style={{
                        ...buttonState.style,
                    }} >
                        <span style={{ color: buttonState.text.color }}>
                            {buttonState.text.value}
                        </span>
                    </div>
                )
            }
        }

        return (
            // <div style={{ margin: `${buttonState.styles.margin ? buttonState.styles.margin : null}` }}>
            //     {
            //         RenderButton()
            //     }
            // </div>
            <>
                {
                    RenderButton()
                }
            </>
        )
    }
}

const styles = {
    wrapper: {
        // height: '50px',
        // minWidth: '150px',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        fontFamily: 'Nunito Sans',
        // fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '22px',
        letterSpacing: '0em',
        textAlign: 'left',

        // margin: '0 15px',
    }
}