import React, { useState } from 'react'
import { 
    colors
} from '../../../../App.json'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { BiCheck, BiUser } from 'react-icons/bi'
import { ImCancelCircle } from 'react-icons/im'
import { Redirect } from 'react-router';

function EmailConfirmAlert(props) {
    const {
        auth
    } = props

    const [redirect, setRedirect] = useState(null)

    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (auth.user && auth.user.email_confirmed) {
        return (
            <div style={{ ...styles.alertWrapper, color: colors.grey3, background: colors.good }}>
                <BiUser />
                <div style={styles.alert}>
                    Your email is verified.
                </div>
                <BiCheck />
            </div>
        )
    } else {
        return (
            <div onClick={() => setRedirect('/email-confirmation')}
                style={{ ...styles.alertWrapper, color: colors.white, background: colors.bad, cursor: 'pointer' }}
            >
                <BiUser />
                <div style={styles.alert}>
                    You are yet to confirm your email address
                </div>
                <ImCancelCircle />
            </div>
        )
    }
    
}


const styles = {
    alertWrapper: {
        alignItems: 'center',
        margin: '50px 0',
        padding: '0 10px',
        height: '51px',
        display: 'flex',
    },
    alert: {
        margin: '50px 0',
        fontSize: '10px',
        padding: '0 10px',
    },
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmAlert)