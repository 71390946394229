import React, { useEffect } from 'react'


window.dataLayer = window.dataLayer || [];
const DataLayer = window.dataLayer;

function gtag() {
    DataLayer.push(arguments);
}

function GoogleAnalytics(props) {
    useEffect(() => {
        // Initialize Google Tag
        gtag('js', new Date());

    }, [])

    return (
        <>
            {props.children}
        </>
    )
}

export {
    gtag
}

export default GoogleAnalytics

