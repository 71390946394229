import React, { useEffect, useState } from 'react'
import ModalOverlay from '../../../../utils/overlay/ModalOverlay'
import {
    colors,
} from '../../../../App.json'

import Button from '../../../../utils/Button';
import { PostMan } from '../../../../Helpers';

import IsDesktop from '../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../utils/breakpoints/IsPhone';

import SwapIcon from '../../../../assets/icons/swap_icon.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


function ModalSubmitSwapOffer(props) {
    const {
        auth,
        hideModal,
        orderPayload,
        orderFormData,
        listing,
    } = props
    
    const [Buttons, setButtons] = useState({
        submitOffer: {
            text: {
                color: colors.white,
                value: "Submit",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '20px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => {},
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
        closeModal: {
            text: {
                color: colors.primary,
                value: "close",
            },
            styles: {
                height: '30px',
                width: null,
                margin: null,
                backgroundColor: null,
                color: colors.white
            },
            onClick: () => hideModal(),
            loader: null,
        },
    })


    const AttemptSubmitOffer = async () => {
        // Start Loader
        let newButtons = Buttons
        newButtons.submitOffer.loader.isLoading = true
        await setButtons({ ...newButtons })

        // Update order payload
        let payload = orderPayload
        payload['duration'] = orderPayload.duration * (7 * 24 * 60 * 60)
        payload['delivery_fee'] = getDeliveryFee()
        payload['service_fee'] = getServiceFee()
        
        // Response Object
        const responseObject = await PostMan(`order/swap-offer/`, 'POST', orderPayload)
        
        // Stop Loader
        newButtons.submitOffer.loader.isLoading = false
        await setButtons({ ...newButtons })
        
        // Handle Response
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            console.log("responseData: ", responseData)
            // Show Success Message before Redirect
            toast.success("Order has been placed successfully")
            setTimeout(() => {
                hideModal()
                return window.location = "/offers/swap"
            }, 3000)
        }
        else {
            console.log("responseObject: ", responseObject)
        }   
    }

    // console.log("orderPayload: ", orderPayload)

    const getDeliveryFee = () => {
        if (orderPayload.duration == 0) {
            return 1500
        }
        return 3200
    }

    const getServiceFee = () => {
        return 400
    }

    const getTotalFee = () => {
        const delivery_fee = getDeliveryFee()
        const service_fee = getServiceFee()
        return parseInt(service_fee) + parseInt(delivery_fee) + parseInt(orderPayload.fee)
    }
    

    useEffect(() => {
        //

    }, [])


    const MainContent = () => {
        return (
            <>
                <div style={styles.header} className="text-center">Submit Offer</div>

                <span className="hover-danger" style={{ display: 'flex', justifyContent: 'flex-end', margin: "0 0 15px" }}>
                    <Button {...Buttons.closeModal} />
                </span>

                <div className="row">
                    <div className="col-12">
                        <div style={{ ...styles.heading, }}>
                            Submit your
                        </div>

                        <div style={{ ...styles.heading, textTransform: 'capitalize' }}>
                            <span style={{ color: colors.primary, marginRight: '10px' }}>
                                {orderPayload._type}
                            </span>
                            Offer
                        </div>
                    </div>


                    <div className="col-12 my-4">

                        <div style={styles.orderFeesWrapper}>
                            <span>Swap Items</span>
                            <span style={{
                                display: 'flex',
                                flexDirection: 'column',
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                            }}>
                                <span style={{
                                    backgroundColor: colors.primary,
                                    color: colors.white,
                                    padding: "5px 15px",
                                    margin: "3px 0"
                                }}>
                                    {listing.game.name} | {listing.console.short_name}
                                </span>

                                <img src={SwapIcon} style={{
                                    height: '20px',
                                    margin: '10px',
                                    alignSelf: 'center',
                                    transform: "rotate(90deg)",
                                }} />

                                {
                                    orderFormData.swap_items.value.map(swap_item => {
                                        return (
                                            <span style={{
                                                backgroundColor: colors.primary,
                                                color: colors.white,
                                                padding: "5px 15px",
                                                margin: "3px 0"
                                            }}>
                                                {swap_item.game.name} | {swap_item.console.short_name}
                                            </span>
                                        )
                                    })
                                }
                            </span>
                        </div>

                        <div className="mt-2" style={styles.orderFeesWrapper}>
                            <span>Extra Settlement</span>
                            <span style={styles.orderFees}>
                                ₦{orderPayload.fee}
                            </span>
                        </div>

                        <div>
                            <div style={{ ...styles.orderFeesWrapper, padding: '15px 15px 5px', fontWeight: 600, }}>
                                <span>Charges</span>
                            </div>

                            <div style={styles.orderFeesWrapper}>
                                <span>Delivery Fee</span>
                                <span style={styles.orderFees}>
                                    {
                                        orderPayload.duration 
                                        && orderPayload.duration != 0
                                        && "Return delivery inc. "
                                    }
                                    ₦{getDeliveryFee()}
                                </span>
                            </div>

                            <div style={styles.orderFeesWrapper}>
                                <span>Service Fee</span>
                                <span style={styles.orderFees}>
                                    ₦{getServiceFee()}
                                </span>
                            </div>
                        </div>

                        <div style={{ ...styles.orderFeesWrapper, padding: '15px 15px', fontWeight: 600, }}>
                            <span>Total Charges</span>
                            <span style={styles.orderFees}>
                                ₦{getTotalFee()}
                            </span>
                        </div>

                        <div style={styles.durationsWrapper}>
                            <span>Duration</span>
                            <span style={{ ...styles.duration, marginLeft: '5px' }}>
                                {
                                    orderPayload.duration == 0 ? (
                                        "Indefinite"
                                    ) : (
                                        <>{orderPayload.duration} weeks</>
                                    )
                                }
                                
                            </span>
                        </div>
                    </div>

                    <div className="col-12 mt-2">
                        <p style={styles.paragrapgh}>
                            Your request would be sent as an offer to the game owner who then reviews and responds either 
                            by "Accepting" the offer, "Rejecting" the offer or "Re-Negotiating" the terms of the offer.
                        </p>

                        <Button {...Buttons.submitOffer}
                            {...{
                                text: {
                                    color: colors.white,
                                    value: "Submit",
                                },
                                styles: {
                                    height: '50px',
                                    width: '100%',
                                    margin: '20px 0',
                                    backgroundColor: colors.primaryLight,
                                    border: {
                                        width: '1px',
                                        style: 'solid',
                                        color: colors.primary,
                                        radius: '4px',
                                    },
                                    color: colors.white
                                },
                            onClick: () => AttemptSubmitOffer()
                            }}

                        />

                        <p style={styles.paragrapgh}>
                            By clicking ‘’Submit’’ you confirm that you have read and agreed to our terms of use and privacy policy
                        </p>
                    </div>
                </div>
            </>
        )
    }


    return (
        <ModalOverlay>
            <div style={styles.wrapper}>

                <IsDesktop>
                    <div style={{
                        ...styles.container,
                        maxHeight: '80vh',
                        overflowY: 'scroll'
                    }}>
                        {
                            MainContent()
                        }
                    </div>
                </IsDesktop>

                <IsTablet>
                    <div style={styles.container}>
                        {
                            MainContent()
                        }
                    </div>
                </IsTablet>

                <IsPhone>
                    <div style={{
                        ...styles.container,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '-webkit-fill-available',
                        minHeight: '-webkit-fill-available',
                    }}>
                        {
                            MainContent()
                        }
                    </div>
                </IsPhone>
                
            </div>

            <ToastContainer />

        </ModalOverlay>
    )
}


const styles = {
    wrapper: { // Centered Content
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        width: "100%",
        height: "100%",
        position: 'relative',
    },

    container: {
        backgroundColor: colors.white,
        width: "450px",
        padding: "20px 30px"
    },
    header: {
        fontFamily: "Nunito Sans",
        fontWeight: 600,
        fontSize: "17px",
        lineHeight: "24px",
        color: colors.black,
        margin: "20px"
    },
    heading: {
        fontFamily: "Roboto",
        fontWeight: 600,
        fontSize: "30px",
        // lineHeight: "35px",
        color: colors.black,
    },
    paragrapgh: {
        fontSize: "14px",
        textAlign: "justify"
    },
    orderFeesWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'Nunito Sans',
        padding: '0 15px'
    },
    orderFees: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: colors.primary
    },
    durationsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'Nunito Sans',
        padding: '20px 50px',

        fontSize: '25px',
        fontWeight: 600,
    },
    duration: {
        fontFamily: 'Roboto',
        // fontSize: '25px',
        // fontWeight: 600,
        color: colors.primary
    }
}



const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSubmitSwapOffer)