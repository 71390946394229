import React from 'react';

import IsDesktop from '../../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../../utils/breakpoints/IsPhone';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


export default function AdBanners() {
    const MainContent = (config) => {
        return (
            <Carousel
                autoPlay={true}
                showThumbs={false}
                swipeable={true}
                stopOnHover={true}
                infiniteLoop={true}
                interval={2500}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                {...config.carousel}
            >
                <img src={require('../../../../../assets/images/ad-banner-accessories.jpg')} style={{
                    ...styles.bannerImage,
                }} />

                <img src={require('../../../../../assets/images/ad-banner-console.jpg')} style={{
                    ...styles.bannerImage,
                }} />

                <img src={require('../../../../../assets/images/ad-banner-gift-cards.jpg')} style={{
                    ...styles.bannerImage,
                }} />
            </Carousel>
        )
    }

    return (
        <div style={{ margin: "20px 0" }}>
            <IsDesktop>
                {
                    MainContent({
                        carousel: {
                            centerSlidePercentage: 33.333,
                            centerMode: true
                        }
                    })
                }
            </IsDesktop>

            <IsTablet>
                {
                    MainContent({
                        carousel: {
                            centerSlidePercentage: 60,
                            centerMode: true
                        }
                    })
                }
            </IsTablet>

            <IsPhone>
                {
                    MainContent({})
                }
            </IsPhone>        
        </div>
    )

}


const styles = {
    bannerImage: {
        width: "95%",
        borderRadius: '15px',
        margin: '10px',
        __hover: {
            display: 'none'
        }
    },
    bannerWrapper: {
        width: '300px',
        height: '150px',
        borderRadius: '10px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        margin: '20px 0'
    },
}