import React from 'react'
import { connect } from 'react-redux'
import { Redirect, useRouteMatch, useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'


function ProtectedRoute(props) {
    const match = useRouteMatch()
    const location = useLocation()
    const {
        auth,
        children
    } = props

    if (auth.loggedIn) {
        // Check phine number is verified
        if (auth.user && !auth.user.mobile_verified) {
            if (location.pathname !== "/phone-verification") {
                return <Redirect to={"/phone-verification"} />
            }
        }
        // Check User has default console.
        else if (auth.user && auth.user.consoles.length === 0) {
            // console.log("No Default Console")
            if (location.pathname !== "/register/extra-information") {
                return <Redirect to={"/register/extra-information"} />
            }
        }
        //
        return children
    }

    // Redirect to Login
    return (
        <Redirect to={{
                pathname: '/login',
                state: {
                    nextUrl: match.url
                }
            }}
        />
    )
}


const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps) (ProtectedRoute)