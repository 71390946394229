import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from "react-router-dom";

// import RootScreen from './screens/RootScreen';

import HomeScreen from "./screens/HomeScreen/Index";
import LandingScreen from "./screens/LandingScreen";

import UserProfileScreen from "./screens/UserProfileScreen/Index";

import LoginScreen from './screens/auth/LoginScreen';
import LogoutScreen from './screens/auth/LogoutScreen';
import RegisterScreenOne from './screens/auth/RegisterScreenOne';
import RegisterScreenTwo from './screens/auth/RegisterScreenTwo';

// import ProfileScreen from './screens/account/ProfileScreen';
import WalletScreen from './screens/account/WalletScreen';

import EmailVerification from "./screens/account/EmailVerification";
import EmailVerificationSuccess from "./screens/account/EmailVerificationSuccess";
import EmailVerificationHandler from "./screens/account/EmailVerificationHandler";
import EmailVerificationLinkHandler from "./screens/account/EmailVerificationLinkHandler";
import PhoneVerification from "./screens/account/PhoneVerification";

import PasswordResetRequest from './screens/auth/passwords/PasswordResetRequest';
import PasswordResetSent from './screens/auth/passwords/PasswordResetSent';
import PasswordReset from './screens/auth/passwords/PasswordReset';

import OrderScreen from "./screens/OrderScreen/Index";
import MyOrdersScreen from "./screens/MyOrdersScreen";
import MySwapOffersScreen from "./screens/MySwapOffersScreen";

import SearchScreen from "./screens/SearchScreen";

import ContactUs from './screens/ContactUs';
import TermsCondition from './screens/TermsCondition';

import ProtectedRoute from "../../utils/ProtectedRoute";


function RouterBase() {

    return (
        <Router>

            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}

            <Switch>
                {/* <ProtectedRoute>
                    <Route path="/account" component={ProfileScreen} />
                </ProtectedRoute> */}

                <Route path="/search">
                    <ProtectedRoute>
                        <SearchScreen />
                    </ProtectedRoute>
                </Route>

                <Route path="/order">
                    <ProtectedRoute>
                        <OrderScreen />
                    </ProtectedRoute>
                </Route>

                <Route path="/orders">
                    <ProtectedRoute>
                        <MyOrdersScreen />
                    </ProtectedRoute>
                </Route>

                <Route path="/offers/swap">
                    <ProtectedRoute>
                        <MySwapOffersScreen />
                    </ProtectedRoute>
                </Route>

                {/* <Route path="/profile">
                    <ProtectedRoute>
                        <ProfileScreen />
                    </ProtectedRoute>
                </Route> */}

                <Route path="/account">
                    <ProtectedRoute>
                        <UserProfileScreen />
                    </ProtectedRoute>
                </Route>

                <Route path="/wallet">
                    <ProtectedRoute>
                        <WalletScreen />
                    </ProtectedRoute>
                </Route>
                
                <Route exact path="/login">
                    <LoginScreen />
                </Route>

                <Route exact path="/logout">
                    <LogoutScreen />
                </Route>

                <Route exact path="/register">
                    <RegisterScreenOne />
                </Route>

                <Route exact path="/register/extra-information">
                    <ProtectedRoute>
                        <RegisterScreenTwo />
                    </ProtectedRoute>
                </Route>

                <Route exact path="/phone-verification">
                    <ProtectedRoute>
                        <PhoneVerification />
                    </ProtectedRoute>
                </Route>

                <Route exact path="/email-confirmation/success">
                    <EmailVerificationSuccess />
                </Route>

                <Route exact path="/email-confirmation/verifying">
                    <ProtectedRoute>
                        <EmailVerificationHandler />
                    </ProtectedRoute>
                </Route>

                <Route exact path="/email-confirmation/:key">
                    <EmailVerificationLinkHandler />
                </Route>

                <Route exact path="/email-confirmation">
                    <ProtectedRoute>
                        <EmailVerification />
                    </ProtectedRoute>
                </Route>

                <Route exact path="/password-reset/request">
                    <PasswordResetRequest />
                </Route>
                <Route exact path="/password-reset/sent">
                    <PasswordResetSent />
                </Route>                
                <Route exact path="/password-reset/">
                    <PasswordReset />
                </Route>
                
                <Route exact path="/get-started" component={LandingScreen} />
                <Route exact path="/contact-us" component={ContactUs} />
                <Route exact path="/terms-and-conditions" component={TermsCondition} />

                <Route path="/" component={HomeScreen} />
                
            </Switch>

        </Router>
    );
}

export default withRouter(RouterBase)