import React from 'react';
import './App.css';

import { Provider } from 'react-redux';
import { store, persistor } from './redux/Store';
import { PersistGate } from 'redux-persist/integration/react';

import { BrowserRouter } from "react-router-dom";

import Router from './site/Router';
import { PostHogProvider} from 'posthog-js/react';
// import GoogleAnalytics from './GoogleAnalytics';

import {
  publicPosthogKey,
  publicPosthogHost
} from './App.json'


function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        
        <BrowserRouter>

          <PostHogProvider 
            apiKey={publicPosthogKey}
            options={{
              api_host: publicPosthogHost,
            }}
          >
            <Router />
          </PostHogProvider>

          {/* <GoogleAnalytics>
            <Router />
          </GoogleAnalytics> */}
          
          {/* <Middleware>
          </Middleware> */}

        </BrowserRouter>

      </PersistGate>
    </Provider>
  );
}

export default App;
