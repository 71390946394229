import React, { useState } from 'react';
import {
    Link,
    withRouter,
} from 'react-router-dom';
import { colors } from '../../../../App.json';
// import { PostMan } from '../../../../Helpers';

import IsDesktop from '../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../utils/breakpoints/IsPhone';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ModalJoinSubscribersList from './ModalJoinSubscribersList';
import Button from '../../../../utils/Button';



function Footer(props) {
    const {
        auth
    } = props

    const {
        footerConfig
    } = props

    const {
        footerStyles,
    } = footerConfig

    const [ShowJoinSubscribersModal, setShowJoinSubscribersModal] = useState(false)

    const [PageButtons, setPageButtons] = useState({
        subscribe: {
            text: {
                color: colors.grey,
                value: "Subscribe",
            },
            styles: {
                height: '50px',
                width: '150px',
                // margin: '50px 0',
                backgroundColor: null,
                border: `1px solid ${colors.grey}`,
                borderRadius: '3px',
                color: colors.grey
            },
            onClick: () => setShowJoinSubscribersModal(true),
        },
    })

    const MainContent = (config) => (
        <div style={{ ...styles.container, backgroundColor: footerStyles && footerStyles.backgroundColor ? footerStyles.backgroundColor : 'transparent' }}>

            {
                ShowJoinSubscribersModal ? (
                    <ModalJoinSubscribersList
                        hideModal={() => setShowJoinSubscribersModal(false)}
                    />
                ) : null
            }

            <div className='container' style={{
                display: 'flex',
                flexDirection: config.linkSection.flexDirection
            }}>

                <div style={{
                    flex: 1,
                    marginBottom: '50px'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Link to="/">
                            <img
                                src={require('../../../../assets/icons/logo.svg')}
                                style={styles.logo}
                            />
                        </Link>

                        <img
                            src={require('../../../../assets/icons/beta.png')}
                            style={{ height: '20px', margin: '0 5px' }}
                        />
                    </div>

                    <p style={{ padding: "30px 0 10px", color: colors.grey }}>
                        To be up to date on promotions, tournaments, give away, 
                        changes and additions to our site, subscribe to our 
                        newsletters today. 
                    </p>

                    <Button {...PageButtons.subscribe} />
                </div>


                <div style={{ 
                    flex: 1
                }}>
                    <div style={{ 
                        color: colors.primary,
                        fontSize: '25px',
                        fontWeight: 600
                    }}>
                        Quick Links
                    </div>

                    <div style={{
                        display: 'flex',
                        margin: '15px 0 '
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1
                        }}>
                            <Link className="hover-primary" to="/" style={styles.footerLink}>
                                Home
                            </Link>
                            {
                                auth.user ? null : (
                                    <Link className="hover-primary" to="/get-started" style={styles.footerLink}>
                                        Get Started
                                    </Link>
                                )
                            }
                            <Link className="hover-primary" to="/contact-us" style={styles.footerLink}>
                                Contact Us
                            </Link>
                            <Link className="hover-primary" to="/terms-and-conditions" style={styles.footerLink}>
                                Terms & Condition
                            </Link>
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1
                        }}>                            
                            {
                                auth.user ? (
                                    <>
                                        <Link className="hover-primary" to="/account" style={styles.footerLink}>
                                            Profile
                                        </Link>
                                        <Link className="hover-primary" to="/account/my-games" style={styles.footerLink}>
                                            My Games
                                        </Link>
                                        <Link className="hover-primary" to="/account/saved-games" style={styles.footerLink}>
                                            Saved Games
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <Link className="hover-primary" to="/login" style={styles.footerLink}>
                                            Sign In
                                        </Link>
                                        <Link className="hover-primary" to="/register" style={styles.footerLink}>
                                            Register
                                        </Link>
                                    </>
                                )
                            }

                            <Link className="hover-primary" to="/search" style={styles.footerLink}>
                                Categories
                            </Link>
                            
                            {/* <Link className="hover-primary" to="/" style={styles.footerLink}>
                                Tournaments
                            </Link> */}

                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    )

    return (
        <>
            <IsDesktop>
                {
                    MainContent({
                        linkSection: {
                            flexDirection: 'row'
                        }                        
                    })
                }
            </IsDesktop>

            <IsTablet>
                {
                    MainContent({
                        linkSection: {
                            flexDirection: 'column'
                        }                        
                    })
                }
            </IsTablet>

            <IsPhone>
                {
                    MainContent({
                        linkSection: {
                            flexDirection: 'column'
                        }                        
                    })
                }
            </IsPhone>
        </>
    )
}


const styles = {
    container: {
        minHeight: "350px",
        width: '100%',
        padding: '50px 0',
        display: 'flex',
        alignItems: 'flex-start',
        background: colors.black,
        zIndex: 99
    },
    footerLink: {
        margin: "10px 0",
        color: colors.grey
    },
    logo: {
        height: '70px'
    },
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        
    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer))