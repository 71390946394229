import React, { useState } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { colors } from '../../../../App.json'

import IsDesktop from '../../../../utils/breakpoints/IsDesktop'
import IsTablet from '../../../../utils/breakpoints/IsTablet'
import IsPhone from '../../../../utils/breakpoints/IsPhone'

import Button from '../../../../utils/Button';

import { GrLocation } from 'react-icons/gr';
import { FaHeart, FaRegHeart } from 'react-icons/fa';

import { PostMan } from '../../../../Helpers';
import OrderCard from './OrderCard';
import Header from './Header'

import SwapIcon from '../../../../assets/icons/swap_icon.png';

import Countdown from 'react-countdown';


function OfferCard(props) {
    const {
        auth,
        self,
        showConfirmModal
    } = props

    const listing = self.listing
    // let game = listing.game    

    // console.log("auth: ", auth)
    // console.log("self: ", self)

    const [Buttons, setButtons] = useState({
        viewOffer: {
            text: {
                color: colors.white,
                value: "View Offer",
            },
            styles: {
                backgroundColor: colors.grey,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                fontSize: '12px',
                color: colors.white
            },
            onClick: () => showConfirmModal(self)
        },
        
    })

    
    const StatusColor = {
        "pending": {
            background: colors.info,
            color: colors.white,
        },
        "accepted": {
            background: colors.success,
            color: colors.white,
        },
        "declined": {
            background: colors.danger,
            color: colors.white,
        },
        "countered": {
            background: colors.grey2,
            color: colors.white,
        },
        "expired": {
            background: colors.warning,
            color: colors.white,
        },
        "completed": {
            background: colors.primary,
            color: colors.white,
        },
    }

    const getPickupAddress = () => {
        if (self.pickup_address) {
            return self.pickup_address.short_address
        } 
        // else if (auth.user.default_address) {
        //     return auth.user.default_address.full_address
        // } 
        else { return null}
    }

    const getCounter = (timerProps) => {
        return (
            <>
                <div style={{ display: 'flex', color: colors.white, alignItems: 'flex-end' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 10px 0 0' }}>
                        <span style={{
                            fontSize: "12px"
                        }}>{timerProps.formatted.days}</span>
                        <span style={{ fontSize: "8px" }}>Days</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 10px 0 0' }}>
                        <span style={{
                            fontSize: "12px"
                        }}>{timerProps.formatted.hours}</span>
                        <span style={{ fontSize: "8px" }}>Hours</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 10px 0 0' }}>
                        <span style={{
                            fontSize: "12px"
                        }}>{timerProps.formatted.minutes}</span>
                        <span style={{ fontSize: "8px" }}>Minutes</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 10px 0 0' }}>
                        <span style={{
                            fontSize: "12px"
                        }}>{timerProps.formatted.seconds}</span>
                        <span style={{ fontSize: "8px" }}>Seconds</span>
                    </div>
                    <div style={{ fontSize: '10px', textAlign: 'center' }}>
                        To Expiry
                    </div>
                </div>
            </>
        )
    }

    const MainContent = (config) => {
        return (
            <div style={{ 
                    ...styles.wrapper, ...config.wrapper
                }}>

                {
                    auth.user && auth.user.id !== self.bill_to ? (
                        <>
                            <div style={{ width: '20%', display: 'flex', flexDirection: 'column', }}>
                                <div style={styles.game.root}>
                                    <img src={listing.game.image} style={styles.game.image} />
                                    <div style={{ ...styles.game.name }}>
                                        {listing.game.name}
                                    </div>
                                    <div style={{ ...styles.game.name, fontSize: '10px' }}>
                                        <span style={{color: colors.black}}>
                                            Console: 
                                        </span> {listing.console.short_name}
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: '15%', ...styles.offerMetaWrapper }}>
                                <img src={SwapIcon} style={{
                                    ...config.swapIcon
                                }} />
                            </div>
                        </>
                    ): null
                }

                <div style={{ 
                    ...styles.offerMetaWrapper, 
                    flexDirection: config.metaWrapper.flexDirection,
                    width: config.metaWrapper.width
                }}>
                    
                    {
                        self.swap_items.length > 1 ? (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                {
                                    self.swap_items.map(swap_item => {
                                        return (
                                            <span style={{
                                                fontSize: "10px",
                                                backgroundColor: colors.primary,
                                                color: colors.white,
                                                padding: "5px 15px",
                                                margin: "3px 0"
                                            }}>
                                                {swap_item.game.name} | {swap_item.console.short_name}
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        ) : (
                            <div style={styles.game.root}>
                                <img src={self.swap_items[0].game.image} style={styles.game.image} />
                                <div style={styles.game.name}>
                                    {self.swap_items[0].game.name}
                                </div>
                                    <div style={{ ...styles.game.name, fontSize: '10px' }}>
                                    <span style={{color: colors.black}}>
                                        Console: 
                                    </span> {self.swap_items[0].console.short_name}
                                </div>
                            </div>
                        )
                    }

                    <div style={{ ...styles.offerCondition, }}>
                        <div style={{ margin: '10px' }}>
                            <span style={styles.offerConditionKey}>Extra Settlement</span>
                            <span style={styles.offerConditionValue}>
                                ₦{parseFloat(self.fee)}
                            </span>
                        </div>

                        <div style={{ margin: '10px' }}>
                            <span style={styles.offerConditionKey}>Duration</span>
                            <span style={styles.offerConditionValue}>
                                {
                                    self.duration == 0 ? (
                                        "Indefinitely"
                                    ) : (
                                        <>{self.duration} Wks</>
                                    )
                                }
                            </span>
                        </div>
                    </div>
                </div>


                {
                    auth.user && auth.user.id == self.bill_to ? (
                        <>
                            <div style={{ ...styles.offerMetaWrapper, width: "15%"}}>
                                <img src={SwapIcon} style={{
                                    height: '30px',
                                    margin: '80px 20px',
                                    ...config.swapIcon
                                }} />
                            </div>

                            <div style={{ width: '20%', display: 'flex', flexDirection: 'column', }}>
                                <div style={styles.game.root}>
                                    <img src={listing.game.image} style={styles.game.image} />
                                    <div style={{ ...styles.game.name }}>
                                        {listing.game.name}
                                    </div>
                                    <div style={{ ...styles.game.name, fontSize: '10px' }}>
                                        <span style={{ color: colors.black }}>
                                            Console:
                                        </span> {listing.console.short_name}
                                        
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null
                }


                <div style={{ 
                    ...styles.offerMetaWrapper, 
                    ...config.actionWrapper,
                    // justifyContent: 'flex-end',
                    // width: config.actionWrapper.width
                }}>
                    <Button {...Buttons.viewOffer} />
                    {/* {
                        // auth.user && auth.user.id == self.bill_to ? (
                        // false ? (
                        //     <div style={{
                        //         display: 'flex',
                        //         flexDirection: 'column',
                        //         alignItems: 'center',
                        //         justifyContent: 'center',
                        //     }}>
                        //         {
                        //             self.status.value == "accepted" && !self.payment ? (
                        //                 <div style={{ display: 'flex', justifyContent: 'center', }}>
                        //                     <div style={{
                        //                         ...styles.offerStatus,
                        //                         backgroundColor: StatusColor[self.status.value].background,
                        //                         color: StatusColor[self.status.value].color,
                        //                     }}>
                        //                         {self.status.display}
                        //                     </div>
                        //                 </div>
                        //             ) : null
                        //         }
                        //     </div>
                        // ) : (
                        //     <Button {...Buttons.viewOffer} />
                        // )
                    } */}
                </div>


                <div style={{
                    backgroundColor: StatusColor[self.status.value].background,
                    color: StatusColor[self.status.value].color,
                    // background: colors.primaryLight,
                    // color: colors.white,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    padding: '10px 15px',
                    fontSize: '12px',
                    textTransform: 'uppercase'
                }}>
                    <Countdown
                        // date={"2021-10-01T00:00:00"}
                        date={new Date(self.has_expired.expires_at)}
                        renderer={timerProps=>{
                            if (timerProps.completed) {
                                // Render a completed state
                                return self.status.display;
                            } else {
                                // Render a countdown
                                return getCounter(timerProps)
                            }
                        }}
                    />
                </div>

                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,

                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: "14px",
                    color: colors.grey3,
                    padding: '10px 15px',
                }}>
                    {/* <span style={{
                        ...styles.addressWrapper,
                        flexDirection: config.address.flexDirection,
                        width: config.address.width
                    }}>
                        <span style={{ fontWeight: 'bold', margin: '0 5px' }}>
                            <GrLocation /> Pick up:
                        </span>

                        <span style={styles.addressValue}>
                            {
                                getPickupAddress()
                            }
                        </span>
                    </span> */}
                    <span style={{
                        ...styles.addressWrapper,
                        flexDirection: config.address.flexDirection,
                        width: config.address.width
                    }}>
                        <span style={{ fontWeight: 'bold', margin: '0 5px' }}>
                            <GrLocation /> Delivery:
                        </span>

                        <span style={styles.addressValue}>
                            {self.delivery_address.short_address}
                        </span>
                    </span>
                </div>
            </div>
        )
    }
    
    return (
        <>
            <IsDesktop>
                {
                    MainContent({
                        wrapper: {
                            flexDirection: "row",
                            padding: "30px 50px 60px",
                        },
                        metaWrapper: {
                            width: "45%",
                            flexDirection: "row",
                        },
                        actionWrapper: {
                            justifyContent: 'flex-end',
                            width: "20%"
                        },
                        address: {
                            flexDirection: "row",
                            width: "auto"
                        },
                        swapIcon: {
                            height: '30px',
                            margin: '80px 20px',
                        }
                    })
                }
            </IsDesktop>

            <IsTablet>
                {
                    MainContent({
                        wrapper: {
                            flexDirection: "column",
                            padding: "30px 50px 70px",
                        },
                        metaWrapper: {
                            width: "45%",
                            flexDirection: "column",
                        },
                        actionWrapper: {
                            margin: "10px 0 0",
                            justifyContent: "center",
                            width: "20%"
                        },
                        address: {
                            flexDirection: "row",
                            width: "300px"
                        },
                        swapIcon: {
                            height: '30px',
                            margin: '20px',
                            alignSelf: 'center',
                            transform: "rotate(90deg)",
                        }
                    })
                }
            </IsTablet>

            <IsPhone>
                {
                    MainContent({
                        wrapper: {
                            flexDirection: "column",
                            padding: "20px 0 100px",
                        },
                        metaWrapper: {
                            width: "100%",
                            flexDirection: "column",
                        },
                        actionWrapper: {
                            margin: "10px 0 0",
                            justifyContent: "center",
                            width: "100%"
                        },
                        address: {
                            flexDirection: "row",
                            width: "200px"
                        },
                        swapIcon: {
                            height: '30px',
                            margin: '20px',
                            alignSelf: 'center',
                            transform: "rotate(90deg)",
                        }
                    })
                }
            </IsPhone>
        </>
    )
}


const styles = {
    wrapper: {
        position: 'relative',
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        background: colors.white,
        border: "1px solid #D0D0D0",
        boxSizing: "border-box",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        margin: "20px 0",
    },
    game: {
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        image: {
            height: "100px",
            width: "100px",
            objectFit: 'cover'
        },
        name: {
            color: colors.primary,
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '12px',

            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        }
    },

    offerMetaWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    offerCondition: {
        display: 'flex',
        flexDirection: 'column',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
    },
    offerConditionKey: {
        color: colors.grey3,
        fontFamily: 'Source Sans Pro',
        fontSize: '10px',
    },
    offerConditionValue: {
        margin: '0 5px',
        padding: '5px 10px',
        backgroundColor: colors.grey1,
        fontSize: '12px',
    },
    offerStatus: {
        margin: '0 5px',
        padding: '5px 10px',
        backgroundColor: colors.grey3,
        color: colors.white
    },

    addressWrapper: { 
        display: 'flex',
        whiteSpace: "nowrap",
                
    },
    addressValue: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    }
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferCard)