import React, { useEffect, useState } from 'react';

import { PostMan } from '../../../Helpers';
import { colors } from '../../../App.json';

import FormField from '../../../utils/FormField';
import Button from '../../../utils/Button';

import GameCard from './GameCard'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRouteMatch } from 'react-router';


export default function GamesList(props) {
    const match = useRouteMatch()
    const gameSlug = match.params.gameSlug

    const [redirect, setRedirect] = useState(null)
    const [Game, setGame] = useState(null)
    
    const [formFields, setFormFields] = useState({
        name: {
            element: 'input',
            value: Game && Game.name,
            label: false,
            labelText: 'Name',
            props: {
                name: 'name_input',
                type: 'text',
                placeholder: 'eg. Mad Max Fury Road III',
                required: true,
            }
        },
        category: {
            element: 'select',
            data: [
                {
                    value: 0,
                    display: '---'
                },
                {
                    value: 'action',
                    display: 'Action'
                },
                {
                    value: 'adventure',
                    display: 'Adventure'
                },
                {
                    value: 'racing',
                    display: 'Racing'
                },
                {
                    value: 'sport',
                    display: 'Sport'
                },
                {
                    value: 'strategy',
                    display: 'Strategy'
                },
                {
                    value: 'others',
                    display: 'Others'
                },
            ],
            value: Game && Game.category || "",
            label: false,
            labelText: 'Category',
            props: {
                name: 'category_input',
                type: 'text',
                placeholder: null,
                required: true
            }
        },
        image: {
            element: 'input',
            value: Game && Game.image || "",
            file: null,
            label: false,
            labelText: 'Game Cover',
            props: {
                name: 'cover_input',
                type: 'file',
                placeholder: 'Upload Game cover',
                required: false,
                accept: "image/*",
            }
        },
        consoles: {
            element: 'select',
            data: [
                {
                    value: '*',
                    label: 'All'
                },
            ],
            value: [],
            label: false,
            labelText: 'Consoles',
            props: {
                name: 'consoles_input',
                type: 'text',
                placeholder: 'consoles',
                required: true,
                isMulti: true,
            },
            styles: {
                border: '1px solid #D0D0D0',
                boxSizing: 'border-box',
                borderRadius: '5px',
                height: '75px',
                width: '100%',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                margin: '10px 0',
                padding: '0 10px',
            }
        },
        consoleFilter: {
            element: 'select',
            data: [
                {
                    value: '*',
                    display: 'All'
                },
            ],
            value: '*',
            label: false,
            labelText: 'Console',
            props: {
                name: 'console_input',
                type: 'text',
                placeholder: null,
                required: false
            }
        },
        categoryFilter: {
            element: 'select',
            data: [
                {
                    value: '*',
                    display: 'All'
                },
                {
                    value: 'action',
                    display: 'Action'
                },
                {
                    value: 'adventure',
                    display: 'Adventure'
                },
                {
                    value: 'racing',
                    display: 'Racing'
                },
                {
                    value: 'sport',
                    display: 'Sport'
                },
                {
                    value: 'strategy',
                    display: 'Strategy'
                },
                {
                    value: 'others',
                    display: 'Others'
                },
            ],
            value: '*',
            label: false,
            labelText: 'Category',
            props: {
                name: 'category_input',
                type: 'text',
                placeholder: null,
                required: true
            }
        },
    })

    const [PageButtons, setPageButtons] = useState({
        upload: {
            text: {
                color: colors.white,
                value: "Update",
            },
            styles: {
                height: '50px',
                width: '50%',
                margin: '30px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => AttemptGameUpdate(),
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
    })

    const FetchGame = async () => {
        const responseObject = await PostMan(`game/${gameSlug}/`, 'GET')
        if (responseObject.status === 'success') {
            let game = responseObject.data
            // Update game in state.
            await setGame(game)
            //
            let newFormFields = formFields
            newFormFields.name.value = game.name
            newFormFields.category.value = game.category
            // newFormFields.console.value = game.console
            await setFormFields({ ...newFormFields })
        }
        else { }
    }

    const FetchConsoleList = async () => {
        const responseObject = await PostMan(`console/all/`, 'GET')
        if (responseObject.status === 'success') {
            let consoleData = responseObject.data
            let newFormFields = formFields
            consoleData.map(console => {
                newFormFields.consoles.data.push({
                    value: console.id,
                    label: console.name,
                })
                newFormFields.consoleFilter.data.push({
                    value: console.id,
                    display: console.name,
                })
            })
            // Update formFields in state.
            await setFormFields({ ...newFormFields })
        }
        else { }
    }

    const AttemptGameUpdate = async () => {
        // Create an object of formData 
        const formPayload = new FormData();
        let gamePayload = {
            name: formFields.name,
            category: formFields.category,
            consoles: formFields.consoles,
            image: formFields.image
        }
        // Start Loader
        let newPageButtons = PageButtons
        newPageButtons.upload.loader.isLoading = true
        await setPageButtons({ ...newPageButtons })
        for (let field in gamePayload) {
            let fieldData = formFields[field]
            if (!fieldData.value || fieldData.value == ' ' || fieldData.value == 0) {
                continue
            }
            if (field === 'consoles') {
                // payload[field] = 
                fieldData.value.map(console_ => {
                    // return console_.value
                    formPayload.append('consoles', console_.value);
                })
            } else {
                formPayload.append(field, fieldData.value);
            }
        }

        const responseObject = await PostMan(`game/${gameSlug}/`, 'PATCH', formPayload, false)

        // Stop Loader
        newPageButtons.upload.loader.isLoading = false
        await setPageButtons({ ...newPageButtons })

        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            
            // Fetch Game List
            window.location.reload()
        }
        else if (responseObject.status === 'bad_request') {
            let responseData = responseObject.data
            for (let key in responseData) {
                if (key === "message") {
                    toast.error(responseData[key])
                } else {
                    let fieldErrors = responseData[key]
                    fieldErrors.map(errorMessage => {
                        // Toast Error Message
                        toast.error(errorMessage)
                    })
                }
            }
        }
        else if (responseObject.status === 'error') {
            // Toast Error Message
            toast.error(responseObject.data.message)
        }

    }

    useEffect(() => {
        // Fetch Console List
        FetchConsoleList()

        // Fetch Game List
        FetchGame()

    }, [])


    const MainContent = () => {
        return (
            <>
                <div style={styles.contentSectionHeader}>
                    Game Details {Game && `- ${Game.name}`}
                </div>

                <div style={{ display: 'flex', }}>

                    <div style={{ width: "400px", }}>
                        <img src={Game && Game.image} style={styles.gameCoverImage} />
                        <div style={{ margin: "10px 0", color: colors.grey3 }}>
                            Consoles: 

                            {
                                Game && Game.consoles.length > 0 && Game.consoles.map(console => {
                                    return (
                                        <span style={{ color: colors.primary, margin: '0 2px 0', fontSize: '12px' }}>
                                            {console.short_name},
                                        </span>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '500px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1, textAlign: 'end', margin: '0 30px 0 0' }}>
                                {formFields.name.labelText}
                            </div>

                            <div style={{ flex: 2 }}>
                                <FormField
                                    formData={formFields}
                                    change={(newFormFields) => setFormFields({ ...newFormFields })}
                                    field={{
                                        id: 'name',
                                        config: formFields.name
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1, textAlign: 'end', margin: '0 30px 0 0' }}>
                                {formFields.category.labelText}
                            </div>

                            <div style={{ flex: 2 }}>
                                <FormField
                                    formData={formFields}
                                    change={(newFormFields) => setFormFields({ ...newFormFields })}
                                    field={{
                                        id: 'category',
                                        config: formFields.category
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1, textAlign: 'end', margin: '0 30px 0 0' }}>
                                {formFields.consoles.labelText}
                            </div>

                            <div style={{ flex: 2 }}>
                                <FormField
                                    formData={formFields}
                                    change={(newFormFields) => {
                                        newFormFields.consoles.value.map(console_ => {
                                            // If "All" consoles selected
                                            if (console_.value === '*') {
                                                // Reset consoles field
                                                newFormFields.consoles.value = []
                                                // Select all consoles
                                                newFormFields.consoles.data.map(console_ => {
                                                    if (console_.value !== '*') {
                                                        newFormFields.consoles.value.push(console_)
                                                    }
                                                })
                                            }
                                        })
                                        setFormFields({ ...newFormFields })
                                    }}
                                    field={{
                                        id: 'consoles',
                                        config: formFields.consoles
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1, textAlign: 'end', margin: '0 30px 0 0' }}>
                                {formFields.image.labelText}
                            </div>

                            <div style={{ flex: 2 }}>
                                <FormField
                                    formData={formFields}
                                    change={(newFormFields) => setFormFields({ ...newFormFields })}
                                    field={{
                                        id: 'image',
                                        config: formFields.image
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1, textAlign: 'end', margin: '0 30px 0 0' }}></div>

                            <div style={{ flex: 2 }}>
                                <Button {...PageButtons.upload} />
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }


    return (
        <div>
            {
                MainContent()
            }
        </div>
    )
}


const styles = {
    contentSectionHeader: {
        color: colors.primary,
        fontWeight: 800,
        fontSize: "18px",
        margin: "0 0 25px"
    },
    contentSection: {
        display: 'flex',
    },

    gameCoverImage: {
        height: "400px",
        width: "400px",
        objectFit: "cover",
        borderRadius: "5px",
        textAlign: 'center'
    },
}