import React, { useEffect, useState } from 'react'
import ModalOverlay from '../../../../utils/overlay/ModalOverlay'
import {
    colors,
    paystack_pub_key
} from '../../../../App.json';

import Button from '../../../../utils/Button';
import { PostMan } from '../../../../Helpers';

import IsDesktop from '../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../utils/breakpoints/IsPhone';

import SwapIcon from '../../../../assets/icons/swap_icon.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { usePaystackPayment } from 'react-paystack';

import mastercard_logo from '../../../../assets/icons/mastercard.png';
import FormField from '../../../../utils/FormField';


function OrderConfirmationModal(props) {
    const {
        auth,
        hideModal,
        offerPayload,
        offerFormData,
        setOfferPayload
    } = props

    console.log("offerPayload: ", offerPayload)
    console.log("offerFormData: ", offerFormData)

    const listing = offerPayload.listing

    const [formData, setFormData] = useState({
        payWithMySettlement: {
            element: 'checkbox',
            checked: false,
            label: true,
            labelText: 'Pay with my settlement',
            props: {
                name: 'rent_input',
                type: 'checkbox',
            }
        },
    })

    const [Buttons, setButtons] = useState({
        confirmOffer: {
            text: {
                color: colors.white,
                value: "Confirm",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '20px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
        acceptOffer: {
            text: {
                color: colors.white,
                value: "Accept",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '20px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
        declineOffer: {
            text: {
                color: colors.white,
                value: "Decline",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '20px 0',
                backgroundColor: colors.danger,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
        closeModal: {
            text: {
                color: colors.primary,
                value: "close",
            },
            styles: {
                height: '30px',
                width: null,
                margin: null,
                backgroundColor: null,
                color: colors.white
            },
            onClick: () => hideModalHandler(),
            loader: null,
        },
    })

    const hideModalHandler = () => {
        if (offerPayload.status.value === "accepted") {
            return window.location.reload()
        } else hideModal()
    }


    // const AttemptConfirmOffer = async () => {
    //     // Start Loader
    //     let newButtons = Buttons
    //     newButtons.confirmOffer.loader.isLoading = true
    //     await setButtons({ ...newButtons })

    //     // Response Object
    //     const responseObject = await PostMan(`order/swap-offer/`, 'POST', offerPayload)
    //     // Stop Loader
    //     newButtons.confirmOffer.loader.isLoading = false
    //     await setButtons({ ...newButtons })
    //     // Handle Response
    //     if (responseObject.status === 'success') {
    //         let responseData = responseObject.data
    //         console.log("responseData: ", responseData)
    //         // Show Success Message before Redirect
    //         toast.success("Order has been placed successfully")
    //         setTimeout(() => {
    //             hideModal()
    //             return window.location = "/offers/swap"
    //         }, 3000)
    //     }
    //     else {
    //         console.log("responseObject: ", responseObject)
    //     }   
    // }

    const PayButton = () => {
        const paymentReference = (new Date()).getTime()
        const config = {
            reference: paymentReference,
            email: auth.user.email,
            amount: getTotalFee() * 100,
            publicKey: paystack_pub_key,
        };

        const onSuccess = () => AttemptPlaceOrder(paymentReference)
        const onClose = () => { }

        const initializePayment = usePaystackPayment(config);
        const onClick = () => initializePayment(onSuccess, onClose)

        let btnColor, txtColor
        btnColor = colors.primaryLight
        txtColor = colors.white

        return (<Button {...Buttons.confirmOffer}
            {...{
                text: {
                    color: txtColor,
                    value: <span>Confirm and Pay <img src={mastercard_logo} /></span>,
                },
                styles: {
                    height: '50px',
                    width: '100%',
                    margin: '20px 0',
                    backgroundColor: btnColor,
                    border: {
                        width: '1px',
                        style: 'solid',
                        color: colors.primary,
                        radius: '4px',
                    },
                    color: colors.white
                },
                onClick: () => onClick()
            }}

        />)
    };

    const AttemptPlaceOrder = async (paymentReference) => {
        // Start Loader
        let newButtons = Buttons
        newButtons.confirmOffer.loader.isLoading = true
        await setButtons({ ...newButtons })
        // Update Payload with payment reference
        
        let payload
        let defaultPayload = {
            _type: 'swap',
            swap_offer: offerPayload.id,
            payment_reference: paymentReference,
            // duration: parseInt(offerPayload.duration) / (7 * 24 * 60 * 60),
            duration: parseInt(offerPayload.duration) * (7 * 24 * 60 * 60),
            delivery_fee: getDeliveryFee(),
            service_fee: getServiceFee(),
        }

        if (auth.user.id == offerPayload.bill_to) {
            payload = {
                ...defaultPayload,
                fee: offerPayload.fee,
                bill_to: offerPayload.bill_to,
                listing: offerPayload.listing.id,
                pickup_address: offerPayload.pickup_address.id,
                delivery_address: offerPayload.delivery_address.id,
            }
            
        } else {
            payload = []
            offerPayload.swap_items.map(swap_item=>{
                let itemPayload = {
                    ...defaultPayload,
                    fee: 0,
                    bill_to: auth.user.id,
                    listing: swap_item.id,
                    pickup_address: offerPayload.delivery_address.id,
                    delivery_address: offerPayload.pickup_address.id,
                }
                payload.push(itemPayload)
            })
        }       

        console.log("payload: ", payload)

        
        // Response Object
        const responseObject = await PostMan(`order/`, 'POST', payload)
        // Stop Loader
        newButtons.confirmOffer.loader.isLoading = false
        await setButtons({ ...newButtons })
        // Handle Response
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            // Show Success Message before Redirect
            toast.success("Order has been placed successfully")
            setTimeout(() => {
                hideModal()
                return window.location = "/orders"
                // return window.location = "/my-orders"
            }, 3000)
        }
        else {
            console.log("responseObject: ", responseObject)
        }
    }

    const AttemptSendOfferResponse = async (response) => {
        let payload = {}
        //  = { status: "accepted" }

        if (response == "accept") {
            // Start Loader
            let newButtons = Buttons
            newButtons.acceptOffer.loader.isLoading = true
            await setButtons({ ...newButtons })
            // Add Status and Pickup Address to payload
            payload['status'] = "accepted"
            payload['pickup_address'] = auth.user && auth.user.default_address.id
        } else if (response == "decline") {
            // Start Loader
            let newButtons = Buttons
            newButtons.declineOffer.loader.isLoading = true
            await setButtons({ ...newButtons })
            // Add Status to payload
            payload['status'] = "declined"
        }

        console.log("payload: ", payload)

        // Response Object
        const responseObject = await PostMan(`order/swap-offer/${offerPayload.id}/`, 'PATCH', payload)

        // Stop Loader
        let newButtons = Buttons
        newButtons.acceptOffer.loader.isLoading = false
        newButtons.declineOffer.loader.isLoading = false
        await setButtons({ ...newButtons })

        // Handle Response
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            console.log("responseData: ", responseData)

            await setOfferPayload({ ...responseData })
            // Show Success Message before Redirect
            if (responseData.status.value == "accepted") {
                toast.success("Offer has been accepted.")
            }
            else if (responseData.status.value == "declined") {
                toast.warning("Offer has been declined.")
            }

            // setTimeout(() => {                
            //     window.location.reload()

            //     // hideModal()
            //     // return window.location = "/orders"

            //     // return window.location = "/my-orders"
            // }, 3000)
        }
        else {
            console.log("responseObject: ", responseObject)
        }
    }

    const getDeliveryFee = () => {
        if (offerPayload.duration == 0) {
            return parseFloat(1500.00)
        }
        return parseFloat(3200.00)
    }

    const getServiceFee = () => {
        return parseFloat(400.00)
    }

    const getTotalFee = () => {
        const delivery_fee = getDeliveryFee()
        const service_fee = getServiceFee()
        const totalFees = service_fee + delivery_fee
        if (auth.user && auth.user.id == offerPayload.bill_to) {
            return totalFees + parseFloat(offerPayload.fee)
        } else {
            return totalFees
        }
    }

    const checkHasPaid = () => {
        let hasPaid = false
        offerPayload.transactions.map(transaction => {
            if (auth.user.id == transaction.user.id) {
                hasPaid = true
            }
        })
        if (hasPaid) return <PayButton />
        else return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: "0 0 20px",
                padding: "20px 0",
                backgroundColor: colors.primary,
                color: colors.white
            }}>
                Order Paid
            </div>
        )
    }

    useEffect(() => {
        //

    }, [])


    const MainContent = () => {
        return (
            <>
                <div style={styles.header} className="text-center">Swap Offer</div>

                <span className="hover-danger" style={{ display: 'flex', justifyContent: 'flex-end', margin: "0 0 15px" }}>
                    <Button {...Buttons.closeModal} />
                </span>

                <div className="row">
                    <div className="col-12">
                        <div style={{ ...styles.heading, }}>
                            Confirm
                        </div>

                        <div style={{ ...styles.heading, textTransform: 'capitalize' }}>
                            <span style={{ color: colors.primary, marginRight: '10px' }}>
                                Swap
                            </span>
                            Offer for
                        </div>
                    </div>


                    <div className="col-12 my-4">

                        <div style={styles.orderMetaWrapper}>
                            {/* <span>Your Listing</span> */}
                            <span style={{
                                display: 'flex',
                                flexDirection: 'column',
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                            }}>
                                <span style={{
                                    backgroundColor: colors.primary,
                                    color: colors.white,
                                    padding: "5px 15px",
                                    margin: "3px 0"
                                }}>
                                    {listing.game.name} | {listing.console.short_name}
                                </span>

                                <img src={SwapIcon} style={{
                                    height: '20px',
                                    margin: '10px',
                                    alignSelf: 'center',
                                    transform: "rotate(90deg)",
                                }} />

                                <span>Swap Items</span>

                                {
                                    offerPayload.swap_items.map(swap_item => {
                                        return (
                                            <span style={{
                                                backgroundColor: colors.primary,
                                                color: colors.white,
                                                padding: "5px 15px",
                                                margin: "3px 0"
                                            }}>
                                                {swap_item.game.name} | {swap_item.console.short_name}
                                            </span>
                                        )
                                    })
                                }
                            </span>
                        </div>

                        {
                            auth.user && auth.user.id == offerPayload.listing.owner.id ? (
                                <div className="mt-2" style={styles.orderMetaWrapper}>
                                    <span>Extra Settlement</span>
                                    <span style={styles.orderFees}>
                                        you receive ₦{parseFloat(offerPayload.fee)}
                                    </span>
                                </div>
                            ) : null
                        }

                        <div>
                            <div style={{ ...styles.orderMetaWrapper, padding: '15px 15px 5px', fontWeight: 600, }}>
                                <span>Charges</span>
                            </div>

                            {
                                auth.user && auth.user.id == offerPayload.bill_to ? (
                                    <div className="mt-2" style={styles.orderMetaWrapper}>
                                        <span>Extra Settlement</span>
                                        <span style={styles.orderFees}>
                                            ₦{parseFloat(offerPayload.fee)}
                                        </span>
                                    </div>
                                ) : null
                            }

                            <div style={styles.orderMetaWrapper}>
                                <span>Delivery Fee</span>
                                <span style={styles.orderFees}>
                                    {
                                        // offerPayload.duration && "Return delivery inc. "
                                        offerPayload.duration != 0 && "Return delivery inc. "
                                    }
                                    ₦{getDeliveryFee()}
                                </span>
                            </div>

                            <div style={styles.orderMetaWrapper}>
                                <span>Service Fee</span>
                                <span style={styles.orderFees}>
                                    ₦{getServiceFee()}
                                </span>
                            </div>
                        </div>

                        <div style={{ ...styles.orderMetaWrapper, padding: '15px 15px', fontWeight: 600, }}>
                            <span>Total Charges</span>
                            <span style={styles.orderFees}>
                                ₦{getTotalFee()}
                            </span>
                        </div>

                        {/* <div style={styles.orderMetaWrapper}>
                            <FormField
                                formData={formData}
                                change={(newFormData) => setFormData({ ...newFormData })}
                                field={{
                                    id: 'payWithMySettlement',
                                    config: formData.payWithMySettlement
                                }}
                            />
                        </div> */}

                        <div style={styles.durationsWrapper}>
                            <span>Duration:</span>
                            <span style={styles.duration}>
                            {/* <span style={{ ...styles.duration, marginLeft: '5px' }}> */}
                                {
                                    offerPayload.duration == 0 ? (
                                        "Indefinitely"
                                    ) : (
                                        <>{offerPayload.duration} weeks</>
                                    )
                                }
                            </span>
                        </div>

                        {
                            auth.user && auth.user.id == offerPayload.bill_to ? null : (
                                <div style={{
                                    borderRadius: '5px',
                                    backgroundColor: colors.grey2,
                                    fontFamily: 'Nunito Sans',
                                    padding: '25px 35px',
                                }}>
                                    <div style={{
                                        fontSize: '18px',
                                        fontWeight: 600,
                                        textAlign: 'center'
                                    }}>
                                        Additional Message
                                    </div>
                                    <p style={styles.duration}>
                                        {offerPayload.additional_info}
                                    </p>
                                </div>
                            )
                        }


                        
                    </div>

                    <div className="col-12 mt-2">

                        {
                            offerPayload.status.value === "pending" ? (
                                <>
                                    {

                                        auth.user && auth.user.id == offerPayload.bill_to ? (
                                            <>
                                                <p style={styles.paragrapgh}>
                                                    Your Swap Offer has been sent to the game owner who has till {new Date(offerPayload.has_expired.expires_at).toDateString()} to
                                                    give a response. Kindly be patient while the order runs its course.
                                                </p>

                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    margin: "0 0 20px",
                                                    padding: "20px 0",
                                                    backgroundColor: colors.info,
                                                    color: colors.white
                                                }}>
                                                    Offer Pending
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <p style={styles.paragrapgh}>
                                                    Upon "Accepting" the offer, whatever "Extra Settlement" that is attached to the offer would be
                                                    credited to your wallet balance as soon as the transaction is concluded.
                                                </p>

                                                <Button {...Buttons.acceptOffer}
                                                    {...{
                                                        onClick: () => AttemptSendOfferResponse("accept")
                                                    }}
                                                />

                                                <Button {...Buttons.declineOffer}
                                                    {...{
                                                        onClick: () => AttemptSendOfferResponse("decline")
                                                    }}
                                                />
                                            </>
                                        )
                                    }
                                </>
                            ) : null
                        }

                        {
                            offerPayload.status.value === "accepted" ? (
                                <>
                                    {
                                        offerPayload.transactions.filter(t => auth.user.id == t.user.id).length > 0 ? (
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                margin: "0 0 20px",
                                                padding: "20px 0",
                                                backgroundColor: colors.primary,
                                                color: colors.white
                                            }}>
                                                Payment Received
                                            </div>
                                        ) : <PayButton />
                                    }
                                </>
                            ) : null
                        }

                        {
                            offerPayload.status.value === "declined" ? (
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: "0 0 20px",
                                    padding: "20px 0",
                                    backgroundColor: colors.danger,
                                    color: colors.white
                                }}>
                                    Offer Declined
                                </div>
                            ) : null
                        }

                        {
                            offerPayload.status.value === "completed" ? (
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: "0 0 20px",
                                    padding: "20px 0",
                                    backgroundColor: colors.primary,
                                    color: colors.white
                                }}>
                                    Order Completed
                                </div>
                            ) : null
                        }

                        <div onClick={() => hideModalHandler()}
                            className='hover-danger'
                            style={{
                                margin: '20px 0px', 
                                color: colors.primary,
                                textAlign: 'center', 
                                fontSize: '12px'
                            }}
                        >
                            Close
                        </div>                        

                    </div>

                </div>
            </>
        )
    }


    return (
        <ModalOverlay>
            <div style={styles.wrapper}>

                <IsDesktop>
                    <div style={{
                        ...styles.container,
                        maxHeight: '80vh',
                        overflowY: 'scroll'
                    }}>
                        {
                            MainContent()
                        }
                    </div>
                </IsDesktop>

                <IsTablet>
                    <div style={styles.container}>
                        {
                            MainContent()
                        }
                    </div>
                </IsTablet>

                <IsPhone>
                    <div style={{
                        ...styles.container,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '-webkit-fill-available',
                        minHeight: '-webkit-fill-available',
                    }}>
                        {
                            MainContent()
                        }
                    </div>
                </IsPhone>

            </div>

            <ToastContainer />

        </ModalOverlay>
    )
}


const styles = {
    wrapper: { // Centered Content
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        width: "100%",
        height: "100%",
        position: 'relative',
    },

    container: {
        backgroundColor: colors.white,
        width: "450px",
        padding: "20px 30px"
    },
    header: {
        fontFamily: "Nunito Sans",
        fontWeight: 600,
        fontSize: "17px",
        lineHeight: "24px",
        color: colors.black,
        margin: "20px"
    },
    heading: {
        fontFamily: "Roboto",
        fontWeight: 600,
        fontSize: "30px",
        // lineHeight: "35px",
        color: colors.black,
    },
    paragrapgh: {
        fontSize: "14px",
        textAlign: "justify"
    },
    orderMetaWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'Nunito Sans',
        padding: '0 15px'
    },
    orderFees: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: colors.primary
    },
    durationsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'Nunito Sans',
        padding: '20px 50px',

        fontSize: '25px',
        fontWeight: 600,
    },
    duration: {
        fontFamily: 'Roboto',
        // fontSize: '25px',
        // fontWeight: 600,
        color: colors.primary
    }
}



const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationModal)