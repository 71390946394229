import React, { useState } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { colors } from '../../../App.json'

import IsDesktop from '../../../utils/breakpoints/IsDesktop'
import IsTablet from '../../../utils/breakpoints/IsTablet'
import IsPhone from '../../../utils/breakpoints/IsPhone'

import Button from '../../../utils/Button';

import { GrLocation } from 'react-icons/gr';
import { FaHeart, FaRegHeart } from 'react-icons/fa';

import { PostMan } from '../../../Helpers';
import OrderCard from './OrderCard';
import Header from './Header'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function OfferCard(props) {
    const {
        auth,
        self
    } = props

    let listing = self.listing
    let game = listing.game    

    console.log("auth: ", auth)
    console.log("self: ", self)

    const [Buttons, setButtons] = useState({
        initiatePickup: {
            text: {
                color: colors.white,
                value: "Initiate Pickup",
            },
            styles: {
                padding: '5px 15px',
                margin: '10px 15px',
                fontSize: '14px',
                backgroundColor: colors.primaryLight,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => InitiateGamePickUp(),
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
        beginGameTest: {
            text: {
                color: colors.white,
                value: "Begin Tests",
            },
            styles: {
                padding: '5px 15px',
                margin: '10px 15px',
                fontSize: '14px',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => BeginGameTests(),
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
        // testPassed: {
        //     text: {
        //         color: colors.white,
        //         value: "Passed",
        //     },
        //     styles: {
        //         padding: '5px 10px',
        //         margin: '10px',
        //         fontSize: '12px',
        //         backgroundColor: colors.success,
        //         borderRadius: '3px',
        //         color: colors.white
        //     },
        //     onClick: () => SetTestVerdict({ passed: true }),
        //     loader: {
        //         isLoading: false,
        //         size: 12,
        //         color: colors.white,
        //     },
        // },
        // testFailed: {
        //     text: {
        //         color: colors.white,
        //         value: "Failed",
        //     },
        //     styles: {
        //         padding: '5px 10px',
        //         margin: '10px',
        //         fontSize: '12px',
        //         backgroundColor: colors.danger,
        //         borderRadius: '3px',
        //         color: colors.white
        //     },
        //     onClick: () => SetTestVerdict({ passed: false }),
        //     loader: {
        //         isLoading: false,
        //         size: 12,
        //         color: colors.white,
        //     },
        // },

        hasBeenPickedUp: {
            text: {
                color: colors.white,
                value: "Pick Up Confirmed?",
            },
            styles: {
                padding: '5px 10px',
                margin: '10px 10px',
                fontSize: '10px',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => SetGamePickedUp(),
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
        hasBeenDelivered: {
            text: {
                color: colors.white,
                value: "Delivery Confirmed?",
            },
            styles: {
                padding: '5px 10px',
                margin: '10px 10px',
                fontSize: '10px',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => SetGameDelivered(),
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },

        
        swap: {
            text: {
                color: colors.primary,
                value: "Swap",
            },
            styles: {
                height: '45px',
                width: '120px',
                margin: '30px 15px',
                backgroundColor: colors.tetiary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            linkTo: {
                pathname: `/order/${game.slug}/swap`,
                state: { game, listing }
            }
        },
        buy: {
            text: {
                color: colors.primary,
                value: "Buy",
            },
            styles: {
                height: '45px',
                width: '120px',
                margin: '30px 15px',
                backgroundColor: colors.grey2,
                border: `1px solid #641E6E`,
                borderRadius: '3px',
                color: colors.white
            },
            linkTo: {
                pathname: `/order/${game.slug}/buy`,
                state: { game, listing }
            }
        }
    })

    
    const StatusColor = {
        "rent": {
            background: colors.primary,
            color: colors.white,
        },
        "swap": {
            background: colors.primaryLight,
            color: colors.white,
        },
        "buy": {
            background: colors.grey2,
            color: colors.primary,
        },

        "pending": { background: colors.grey3, },
        "paid": { background: colors.success, },
        "pick_up_in_progress": { background: colors.info, },
        "test_in_progress": { background: colors.warning, },
        "test_failed": { background: colors.danger, },
        "in_transit_delivery": { background: colors.info, },
        "in_progress": { background: colors.success, },
        "in_transit_return": { background: colors.success, },
        "completed": { background: colors.success, },
    }
    
    if (self.status.value) {
        console.log("self.status.value: ", self.status.value)
        console.log("StatusColor[self.status.value]: ", StatusColor[self.status.value].background)
    }

    const getPickupAddress = () => {
        if (self.pickup_address) {
            return self.pickup_address.full_address
        } else if (auth.user.default_address) {
            return auth.user.default_address.full_address
        } else { return null}
    }

    const InitiateGamePickUp = async () => {
        // Start Loader
        let newButtons = Buttons
        newButtons.initiatePickup.loader.isLoading = true
        await setButtons({ ...newButtons })
        //
        let payload = {
            pick_up_initiated: true,
        }
        const responseObject = await PostMan(`order/${self.id}/`, 'PATCH', payload)
        //
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            // Toast Success message
            toast.success("Pick up initiated.")
            setTimeout(() => {
                // Reload Page
                window.location.reload()
            }, 2000)
        }
        else {
            console.log("responseObject: ", responseObject)
        }
    }

    const BeginGameTests = async () => {
        // Start Loader
        let newButtons = Buttons
        newButtons.initiatePickup.loader.isLoading = true
        await setButtons({ ...newButtons })
        //
        let payload = {
            has_been_picked: true,
        }
        const responseObject = await PostMan(`order/${self.id}/`, 'PATCH', payload)
        //
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            // Toast Success message
            toast.success("Game has been picked up")
            setTimeout(() => {
                // Reload Page
                window.location.reload()
            }, 2000)
        }
        else {
            console.log("responseObject: ", responseObject)
        }
    }

    const SetGamePickedUp = async () => {
        // Start Loader
        let newButtons = Buttons
        newButtons.hasBeenDelivered.loader.isLoading = true
        await setButtons({ ...newButtons })
        //
        let payload = {
            has_been_picked: true,
        }
        const responseObject = await PostMan(`order/${self.id}/`, 'PATCH', payload)
        // Stop Loader
        newButtons.hasBeenDelivered.loader.isLoading = false
        await setButtons({ ...newButtons })
        //
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            // Toast Success message
            toast.success("Game has been picked. In transit to 3rd party.")
            setTimeout(() => {
                // Reload Page
                window.location.reload()
            }, 2000)
        }
        else {
            console.log("responseObject: ", responseObject)
        }
    }

    // const SetTestVerdict = async (props) => {
    //     const {
    //         passed
    //     } = props
    //     let payload
    //     // Start Loader
    //     let newButtons = Buttons
    //     if (passed) {
    //         newButtons.testPassed.loader.isLoading = true
    //         await setButtons({ ...newButtons })
    //         //
    //         payload = {
    //             is_approved: true,
    //         }
    //     } else {
    //         newButtons.testFailed.loader.isLoading = true
    //         await setButtons({ ...newButtons })
    //         //
    //         payload = {
    //             status: "test_failed",
    //             is_cancelled: true,
    //         }
    //     }
    //     // Send Request
    //     const responseObject = await PostMan(`order/${self.id}/`, 'PATCH', payload)
    //     // Stop Loader
    //     newButtons.testPassed.loader.isLoading = false
    //     newButtons.testFailed.loader.isLoading = false
    //     await setButtons({ ...newButtons })
    //     //
    //     if (responseObject.status === 'success') {
    //         let responseData = responseObject.data
    //         // Toast Success message
    //         toast.success("Order updated successfully")
    //         setTimeout(() => {
    //             // Reload Page
    //             window.location.reload()
    //         }, 2000)
    //     }
    //     else {
    //         console.log("responseObject: ", responseObject)
    //     }
    // }

    const SetGameDelivered = async () => {
        // Start Loader
        let newButtons = Buttons
        newButtons.hasBeenDelivered.loader.isLoading = true
        await setButtons({ ...newButtons })
        //
        let payload = {
            has_been_delivered: true,
        }
        const responseObject = await PostMan(`order/${self.id}/`, 'PATCH', payload)
        // Stop Loader
        newButtons.hasBeenDelivered.loader.isLoading = false
        await setButtons({ ...newButtons })
        //
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            // Toast Success message
            toast.success("Game has been delivered")
            setTimeout(() => {
                // Reload Page
                window.location.reload()
            }, 2000)
        }
        else {
            console.log("responseObject: ", responseObject)
        }
    }

    const MainContent = (config) => {
        return (
            <div style={{ ...styles.wrapper, padding: config.wrapper.padding, }}>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'column', }}>
                    <div style={styles.game.root}>
                        <img src={listing.game.image} style={styles.game.image} />
                        <div style={styles.game.name}>
                            {listing.game.name}
                        </div>
                    </div>
                </div>

                <div style={{ width: '50%', ...styles.orderMetaWrapper, flexDirection: config.metaWrapper.flexDirection }}>
                    <div style={{ ...styles.orderCondition, }}>
                        <div style={{ margin: '10px' }}>
                            <span style={styles.orderConditionKey}>Fee</span>
                            <span style={styles.orderConditionValue}>
                                ₦{self.fee}
                            </span>
                        </div>

                        {
                            self._type == 'buy' ? null : (
                                <div style={{ margin: '10px' }}>
                                    <span style={styles.orderConditionKey}>Duration</span>
                                    <span style={styles.orderConditionValue}>
                                        {self.duration} Wks
                                        {/* {moment.duration(self.duration).asSeconds()} Wks */}
                                    </span>
                                </div>
                            )
                        }
                    </div>


                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                        <div style={{ 
                            ...styles.orderStatus,
                            color: colors.white, 
                            backgroundColor: StatusColor[self.status.value].background }}
                        >
                            {self.status.display}
                        </div>

                        {
                            self.is_paid ? (
                                self.pick_up_initiated ? (


                                    self.has_been_picked ? (
                                        self.status.value == "in_transit_delivery" ? (
                                            <Button {...Buttons.hasBeenDelivered} />
                                        ) : null
                                    ) : (
                                        self.status.value == "pick_up_in_progress" ? (
                                            <Button {...Buttons.hasBeenPickedUp} />
                                        ) : null
                                        
                                    )


                                    // self.has_been_picked ? (
                                    //     self.status.value == "test_in_progress" ? (
                                    //         <div style={{ display: 'flex' }}>
                                    //             <Button {...Buttons.testPassed} />
                                    //             <Button {...Buttons.testFailed} />
                                    //         </div>
                                    //     ) : (
                                    //         self.status.value == "in_transit_delivery" ? (
                                    //             <Button {...Buttons.hasBeenDelivered} />
                                    //         ) : null
                                    //     )
                                    // ) : <Button {...Buttons.beginGameTest} />
                                ) : <Button {...Buttons.initiatePickup} />
                            ) : null
                        }
                        
                    </div>
                </div>

                <div style={{
                    backgroundColor: StatusColor[self._type].background,
                    color: StatusColor[self._type].color,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    padding: '10px 15px',
                    fontSize: '12px',
                    textTransform: 'uppercase'
                }}>
                    {self._type}
                </div>

                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,

                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: "14px",
                    color: colors.grey3,
                    padding: '10px 15px',
                }}>
                    <span style={{
                        ...styles.addressWrapper,
                        flexDirection: config.address.flexDirection,
                        width: config.address.width
                    }}>
                        <span style={{ fontWeight: 'bold', margin: '0 5px' }}>
                            <GrLocation /> Delivery:
                        </span>

                        <span style={styles.addressValue}>
                            {/* {self.delivery_address.short_address} */}
                            {self.delivery_address && self.delivery_address.address_line}
                        </span>
                    </span>

                    <span style={{ 
                            ...styles.addressWrapper, 
                            flexDirection: config.address.flexDirection,
                            width: config.address.width
                        }}>
                        <span style={{ fontWeight: 'bold', margin: '0 5px' }}>
                            <GrLocation /> Pick up:
                        </span>

                        <span style={styles.addressValue}>
                            {self.pickup_address && self.pickup_address.address_line}
                        </span>
                    </span>
                </div>
            </div>
        )
    }
    
    return (
        <>
            <IsDesktop>
                {
                    MainContent({
                        wrapper: {
                            padding: "30px 50px 60px",
                        },
                        metaWrapper: {
                            flexDirection: "row",
                        },
                        address: {
                            flexDirection: "row",
                            width: "auto"
                        }
                    })
                }
            </IsDesktop>

            <IsTablet>
                {
                    MainContent({
                        wrapper: {
                            padding: "30px 50px 70px",
                        },
                        metaWrapper: {
                            flexDirection: "column",
                        },
                        address: {
                            flexDirection: "row",
                            width: "300px"
                        }
                    })
                }
            </IsTablet>

            <IsPhone>
                {
                    MainContent({
                        wrapper: {
                            padding: "30px 20px 100px",
                        },
                        metaWrapper: {
                            flexDirection: "column",
                        },
                        address: {
                            flexDirection: "column",
                            width: "200px"
                        }
                    })
                }
            </IsPhone>

            <ToastContainer />
        </>
    )
}


const styles = {
    wrapper: {
        position: 'relative',
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        background: colors.white,
        border: "1px solid #D0D0D0",
        boxSizing: "border-box",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        margin: "20px 0",
    },
    game: {
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        image: {
            height: "100px",
            width: "100px",
            objectFit: 'cover'
        },
        name: {
            color: colors.primary,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
        }
    },

    orderMetaWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    orderCondition: {
        display: 'flex',
        flexDirection: 'column',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
    },
    orderConditionKey: {
        color: colors.grey3,
        fontFamily: 'Source Sans Pro',
    },
    orderConditionValue: {
        margin: '0 5px',
        padding: '5px 10px',
        backgroundColor: colors.grey1
    },
    orderStatus: {
        margin: '0 5px',
        padding: '5px 10px',
        backgroundColor: colors.grey3,
        fontSize: '10px',
    },
    addressWrapper: { 
        display: 'flex',
        whiteSpace: "nowrap",
        maxWidth: "500px"
    },
    addressValue: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    }
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferCard)