import React, { useEffect, useState } from 'react'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ModalOverlay from '../../../../utils/overlay/ModalOverlay'
import {
    colors,
} from '../../../../App.json'

import IsDesktop from '../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../utils/breakpoints/IsPhone';

import FormField from '../../../../utils/FormField';
import { Link, Redirect } from 'react-router-dom';
import Button from '../../../../utils/Button';
import { PostMan, FormatDate } from '../../../../Helpers';
import SearchableInput from '../../../../utils/SearchableInput';
import {
    updateUser,
} from '../../../../redux/actions/AuthActions'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetConnectionOptions } from '../../../../vendor/DojahKYC/Dojah';


function ModalStartKYCVerification(props) {
    const {
        hideModal,
        listing=null,
        auth,
        updateUser,
    } = props

    const [CurrentStep, setCurrentStep] = useState(1)
    const [Buttons, setButtons] = useState({
        preKYC: {
            text: {
                color: colors.white,
                value: "Next",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '20px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => setCurrentStep(2),
            loader: null,
        },
        startKYCPrevious: {
            text: {
                color: colors.white,
                value: "Previous",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '20px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => setCurrentStep(1),
            loader: null,
        },
        startKYCNext: {
            text: {
                color: colors.white,
                value: "Next",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '20px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => StartKYCVerification(),
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
        closeModal: {
            text: {
                color: colors.primary,
                value: "close",
            },
            styles: {
                height: '30px',
                width: null,
                margin: null,
                backgroundColor: null,
                color: colors.white
            },
            onClick: () => hideModal(),
            loader: null,
        },
    })

    const [formData, setformData] = useState({
        first_name: {
            element: 'input',
            value: auth.user && auth.user.first_name,
            label: true,
            labelText: 'First name',
            props: {
                name: 'first_name_input',
                type: 'text',
                placeholder: 'Enter first name',
                required: true,
            }
        },
        last_name: {
            element: 'input',
            value: auth.user && auth.user.last_name,
            label: true,
            labelText: 'Last name',
            props: {
                name: 'last_name_input',
                type: 'text',
                placeholder: 'Enter last name',
                required: true,
            }
        },
        dob: {
            element: 'calendar',
            // value: new Date(),
            value: "",
            label: true,
            labelText: 'Date of birth',
            props: {
                type: 'calendar',
                required: true,
                defaultView: "century"
            }
        },
    })

    const StartKYCVerification = async() => {
        // Start Loader
        let newButtons = Buttons
        newButtons.startKYCNext.loader.isLoading = true
        await setButtons({ ...newButtons })
        let formPayload = {}
        // Validate Fields
        for (let fieldName in formData) {
            let fieldData = formData[fieldName]
            // console.log("required: ", fieldData.props.required)
            if (fieldData.props.required) {
                if (!fieldData.value || fieldData.value == ' ' || fieldData.value == 0) {
                    // Stop Loader
                    newButtons.startKYCNext.loader.isLoading = false
                    await setButtons({ ...newButtons })
                    // Toast Error Message
                    toast.error(`${fieldData.labelText} field is required!`)
                    return
                }
            }
            // Set Payload item
            if (fieldName == "dob") {
                //
                formPayload[fieldName] = FormatDate(fieldData.value, "yyyy-MM-dd")
            } else {
                formPayload[fieldName] = fieldData.value
            }
            
        }

        console.log("formPayload: ", formPayload)

        const DojahConfigOptions = GetConnectionOptions({
            user: auth.user,
            first_name: formPayload.first_name,
            last_name: formPayload.last_name,
            dob: formPayload.dob,  // "2022-03-12"
            serviceType: "custom",
            onSuccess: async function (response) {
                console.log('Success', response);
                let payload = {
                    user: {
                        id: auth.user.id,
                        ...formPayload,
                    },
                    response: {
                        ...response
                    }
                }
                const responseObject = await PostMan(
                    `account/verification/kyc/`, 
                    'POST', 
                    JSON.stringify({ json_response: payload })
                )
                // Stop Loader
                newButtons.startKYCNext.loader.isLoading = false
                await setButtons({ ...newButtons })
                
                if (responseObject.status === 'success') {
                    let responseData = responseObject.data
                    // Toast Success message
                    toast.success(responseData.message)
                    setTimeout(()=> {
                        // Hide modal
                        hideModal()
                        // Reload Page
                        window.location.reload()
                    }, 2000)
                }
                else {
                    console.log("responseObject: ", responseObject)
                }
            },
            onError: function (err) {
                console.log('Error', err);
            },
            onClose: async function () {
                console.log('Widget closed');
                // Stop Loader
                newButtons.startKYCNext.loader.isLoading = false
                await setButtons({ ...newButtons })
            }
        })

        const Dojah = new window.Connect(DojahConfigOptions);
        await Dojah.setup();
        await Dojah.open();
    }

    const MainContent = () => {
        return (
            <>
                <div style={styles.header} className="text-center">
                    KYC Verification
                </div>

                <span style={{ display: 'flex', justifyContent: 'flex-end', }}>
                    <Button {...Buttons.closeModal} />
                </span>

                <div className="row mt-3">
                    <div className="col-12">
                        <p style={styles.paragrapgh}>
                            Ensure all information below matches that on your Government issued ID card.
                        </p>
                    </div>

                    {
                        CurrentStep == 2 ? (
                            <>
                                <div className="col-12">
                                    <FormField
                                        formData={formData}
                                        change={(newformData) => setformData({ ...newformData })}
                                        field={{
                                            id: 'dob',
                                            config: formData.dob
                                        }}
                                    />
                                </div>

                                <div className="col-6">
                                    <Button {...Buttons.startKYCPrevious} />
                                </div>
                                <div className="col-6">
                                    <Button {...Buttons.startKYCNext} />
                                </div>                                
                            </>
                        ) : (
                            <>
                                <div className="col-12">
                                    <FormField
                                        formData={formData}
                                        change={(newformData) => setformData({ ...newformData })}
                                        field={{
                                            id: 'first_name',
                                            config: formData.first_name
                                        }}
                                    />
                                </div>

                                <div className="col-12">
                                    <FormField
                                        formData={formData}
                                        change={(newformData) => setformData({ ...newformData })}
                                        field={{
                                            id: 'last_name',
                                            config: formData.last_name
                                        }}
                                    />
                                </div>

                                <div className="col-12">
                                    <Button {...Buttons.preKYC} />
                                </div>
                            </>
                        )
                    }

                </div>
            </>
        )
    }


    return (
        <ModalOverlay>
            <div style={styles.wrapper}>
                

                <IsDesktop>
                    <div style={styles.container}>

                        {
                            MainContent()
                        }
                    </div>
                </IsDesktop>

                <IsTablet>
                    <div style={styles.container}>
                        {
                            MainContent()
                        }
                    </div>
                </IsTablet>

                <IsPhone>
                    <div style={{
                        ...styles.container,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '-webkit-fill-available',
                        minHeight: '-webkit-fill-available',
                    }}>
                        {
                            MainContent()
                        }
                    </div>
                </IsPhone>
            </div>

            <ToastContainer />

        </ModalOverlay>
    )
}


const styles = {
    wrapper: { // Centered Content
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        width: "100%",
        height: "100%",
        position: 'relative',
    },

    container: {
        backgroundColor: colors.white,
        width: "450px",
        padding: "20px 30px",
    },

    header: {
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "17px",
        lineHeight: "24px",
        color: colors.black,
        margin: "20px"
    },
    paragrapgh: {
        fontSize: "14px",
        textAlign: "justify"
    },
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        updateUser
    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalStartKYCVerification)