import React, { Component } from 'react';
import Header from '../../../components/main/Header';
import {
    colors
} from '../../../../../App.json'
import Button from '../../../../../utils/Button';
import LeftPanel from '../../../components/auth/LeftPanel';
import FormField from '../../../../../utils/FormField';

import Breakpoint from '../../../../../utils/breakpoints/Base';
import IsDesktop from '../../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../../utils/breakpoints/IsPhone';


export default class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            buttons: {
                resetPassword: {
                    text: {
                        color: colors.white,
                        value: "Reset Password",
                    },
                    styles: {
                        width: '100%',
                        backgroundColor: colors.primary,
                        color: colors.white
                    },
                    linkTo: "/password-reset/sent",
                },
            },
            headerConfig: { isVisible: false },
            formData: {
                email: {
                    element: 'input',
                    value: '',
                    label: true,
                    labelText: 'Email Address',
                    props: {
                        name: 'username_input',
                        type: 'text',
                        placeholder: 'Enter email address',
                        disabled: true,
                    }
                },
            }
        }
    }

    mainContent = (config) => {
        const {
            buttons,
            formData
        } = this.state

        return (
            <div style={{ ...styles.panelRight, padding: `${config.containerPadding}`, }}>

                <div style={{ ...styles.heading, fontSize: `${config.headingSize}` }}>
                    Reset Your Password
                </div>

                <div style={{ marginTop: '35px', minWidth: `${config.formMinWidth}` }}>
                    <form>
                        <FormField
                            formData={formData}
                            change={(newFormData) => this.setState({
                                formData: newFormData
                            })}
                            field={{
                                id: 'email',
                                config: formData.email
                            }}
                        />

                        <Button {...buttons.resetPassword} />

                    </form>
                </div>

                <Breakpoint name="notPhone">
                    <div style={{ ...styles.dottedSquare }}>
                        <img src={require('../../../../../assets/icons/dotted-square-colored.png')} />
                    </div>
                </Breakpoint>
            </div>
        )
    }


    render() {

        return (
            <div>
                <Header {...this.props} headerConfig={this.state.headerConfig} />

                <IsDesktop>
                    <div style={styles.container.desktop}>
                        <LeftPanel />

                        <div style={{ padding: '0 50px', height: '100%' }}>
                            {
                                this.mainContent({
                                    formMinWidth: '450px',
                                    headingSize: '34px',
                                    containerPadding: '200px 50px 0',
                                })
                            }
                        </div>

                    </div>
                </IsDesktop>

                <IsTablet>
                    <div style={styles.container.tablet}>
                        {
                            this.mainContent({
                                formMinWidth: null,
                                headingSize: '34px',
                                containerPadding: '150px 50px 0',
                            })
                        }
                    </div>
                </IsTablet>

                <IsPhone>
                    <div style={styles.container.phone}>
                        {
                            this.mainContent({
                                formMinWidth: '200px',
                                headingSize: '24px',
                                containerPadding: '100px 50px 0',
                            })
                        }
                    </div>
                </IsPhone>

            </div>
        )
    }
}


const styles = {
    container: {
        desktop: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
        },
        tablet: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 50px',
        },
        phone: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    panelRight: {
        padding: '120px 50px 0',
        height: '100%',
    },
    heading: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 800,
        lineHeight: '40px',
        color: colors.primary,
    },
    subHeading: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: colors.secondary,

        margin: '10px 0'
    },

    formLabel: {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
    },
    dottedSquare: {
        position: 'fixed',
        right: '50px',
        bottom: '50px',
    },
}