import React, { useEffect, useState } from 'react'
import ModalOverlay from '../../../../utils/overlay/ModalOverlay'
import {
    colors,
    cities
} from '../../../../App.json'

import FormField from '../../../../utils/FormField';
import { Link, Redirect } from 'react-router-dom';
import Button from '../../../../utils/Button';
import { PostMan } from '../../../../Helpers';

import IsDesktop from '../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../utils/breakpoints/IsPhone';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


function UploadGameModal(props) {
    const{
        auth,
        hideModal,
        deliveryFormData
    } = props

    const [Address, setAddress] = useState({
        addressLine: "",
        state: null,
        country: null,
    })

    const [Buttons, setButtons] = useState({
        confirmAddress: {
            text: {
                color: colors.white,
                value: "Confirm Address",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '20px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => AttemptAddAddress(),
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
        closeModal: {
            text: {
                color: colors.primary,
                value: "close",
            },
            styles: {
                height: '30px',
                width: null,
                margin: null,
                backgroundColor: null,
                color: colors.white
            },
            onClick: () => hideModal(),
            loader: null,
        },
    })

    
    const ParseAddress = () => {        
        let addressObj = deliveryFormData.address_line.value

        let stateId = deliveryFormData.state.value
        let state
        deliveryFormData.state.data.map(stateObj => {
            if (stateObj.value === parseInt(stateId)) { return state = stateObj.display }
        })

        let countryId = deliveryFormData.country.value
        let country
        deliveryFormData.country.data.map(countryObj => {
            if (countryObj.value === parseInt(countryId)) { return country = countryObj.display }
        })

        setAddress({
            addressLine: addressObj.formatted_address,
            placeId: addressObj.place_id,
            latitude: addressObj.geometry.location.lat.toFixed(6),
            longitude: addressObj.geometry.location.lng.toFixed(6),
            state: {
                id: parseInt(stateId),
                name: state
            },
            country: {
                id: parseInt(countryId),
                name: country
            },
        })
    }

    const AttemptAddAddress = async () => {
        // Start Loader
        let newButtons = Buttons
        newButtons.confirmAddress.loader.isLoading = true
        await setButtons({ ...newButtons })
        // Payload
        let payload = {
            "user": auth.user.id,
            "address_line": Address.addressLine,
            "place_id": Address.placeId,
            "latitude": Address.latitude,
            "longitude": Address.longitude,
            "state": Address.state.id,
        }
        // Response Object
        const responseObject = await PostMan(`address/`, 'POST', payload)
        // Stop Loader
        newButtons.confirmAddress.loader.isLoading = false
        await setButtons({ ...newButtons })
        // Handle Response
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            hideModal()
            return window.location.reload()
        }
        else {
            console.log("responseObject: ", responseObject)
        }
    }


    useEffect(() => {
        // Parse and set Address Data
        ParseAddress()

    }, [])


    const MainContent = () => {
        return (
            <>
                <div style={styles.header} className="text-center">Confirm Your Address</div>

                <span className="hover-danger" style={{ display: 'flex', justifyContent: 'flex-end', margin: "0 0 15px" }}>
                    <Button {...Buttons.closeModal} />
                </span>

                <div className="row">
                    <div className="col-4" style={{ color: colors.grey }}>
                        Address Line
                    </div>
                    <div className="col-8">
                        {Address.addressLine}
                    </div>

                    <div className="col-4" style={{ color: colors.grey }}>
                        State
                    </div>
                    <div className="col-8">
                        {Address.state && Address.state.name}
                    </div>

                    <div className="col-4" style={{ color: colors.grey }}>
                        Country
                    </div>
                    <div className="col-8">
                        {Address.country && Address.country.name}
                    </div>

                    <div className="col-12 mt-3">

                        <Button {...Buttons.confirmAddress} {...{ onClick: () => AttemptAddAddress() }} />

                        <p style={styles.paragrapgh}>
                            By clicking ‘’Confirm Address’’ you confirm that the above address is correct.
                        </p>
                    </div>

                </div>
            </>
        )
    }

    return (
        <ModalOverlay>
            <div style={styles.wrapper}>
                <IsDesktop>
                    <div style={styles.container}>
                        {
                            MainContent()
                        }
                    </div>
                </IsDesktop>

                <IsTablet>
                    <div style={styles.container}>
                        {
                            MainContent()
                        }
                    </div>
                </IsTablet>

                <IsPhone>
                    <div style={{
                        ...styles.container,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '-webkit-fill-available',
                        minHeight: '-webkit-fill-available',
                    }}>
                        {
                            MainContent()
                        }
                    </div>
                </IsPhone>
            </div>

            <ToastContainer />

        </ModalOverlay>
    )
    
}


const styles = {
    wrapper: { // Centered Content
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        width: "100%",
        height: "100%",
        position: 'relative',
    },

    container: {
        backgroundColor: colors.white,
        width: "450px",
        padding: "20px 30px"
    },

    header: {
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "17px",
        lineHeight: "24px",
        color: colors.black,
        margin: "20px"
    },
    paragrapgh: {
        fontSize: "14px",
        textAlign: "justify"
    },
}



const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        
    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadGameModal)