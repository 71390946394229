import React, { useEffect, useState } from 'react'
import {
    colors
} from '../../../../App.json'
import Button from '../../../../utils/Button'
import FormField from '../../../../utils/FormField'
import {
    Link,
    Redirect,
    withRouter,
    useLocation,
    useRouteMatch
} from 'react-router-dom';
import {
    logout
} from '../../../../redux/actions/AuthActions';
import { PostMan } from '../../../../Helpers';
import Notify from '../../../../utils/Notify';
import Breakpoint from '../../../../utils/breakpoints/Base';
import IsDesktop from '../../../../utils/breakpoints/IsDesktop';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { AiOutlineSearch } from 'react-icons/ai';
import { FaChevronDown, FaPowerOff, FaSignInAlt, FaTimes } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';

import ModalGameUpload from './ModalGameUpload';
import ModalConfirmOrder from './ModalConfirmOrder';
import SearchableInput from '../../../../utils/SearchableInput';
import Dropdown from '../../../../utils/Dropdown';

import { RenderNotifications } from '../main/NotifierScripts';
import ModalOverlay from '../../../../utils/overlay/ModalOverlay';

import { gtag } from '../../../../GoogleAnalytics';


function Header(props) {
    const { auth, headerConfig } = props
    
    const match = useRouteMatch();
    const location = useLocation()
    const { state } = location


    const [redirect, setRedirect] = useState(false)

    const [ShowMobileSideMenu, setShowMobileSideMenu] = useState(false)

    const [Searching, setSearching] = useState(false)
    const [GameSearchResult, setGameSearchResult] = useState([])
    const [SelectedGameListing, setSelectedGameListing] = useState(null)
    
    const [Wallet, setWallet] = useState(null)

    const [ShowUploadGameModal, setShowUploadGameModal] = useState(false)
    const [ShowModalConfirmOrder, setShowModalConfirmOrder] = useState(false)

    const getUserInitials = () => {
        if (auth.user.full_name) {
            let userFullName = auth.user.full_name
            let firstName = userFullName.split(' ')[0]
            let lastName = userFullName.split(' ')[1]
            return `${firstName[0]}${lastName[0]}`.toUpperCase()
        }
        return `${auth.user.username[0]}${auth.user.username[0]}`.toUpperCase()

    }

    const [HasNotification, setHasNotification] = useState(false)
    const [Notifications, setNotifications] = useState({
        title: <Notify notification={HasNotification} />,
        data: [],
        wrapperStyles: {
            placeholder: {
                padding: "5px 0",
            },
            dropdown: {
                position: 'absolute',
                width: '275px',
                backgroundColor: colors.grey2,
                top: '50px',
                marginLeft: '-180px',
                borderRadius: '5px',
                border: '1px solid #D0D0D0',
            },
            dropdownItem: {
                color: colors.dark,
                fontSize: '12px',
                padding: '10px 15px',
                borderTop: `1px solid ${colors.grey}`
            },
            toolTip: {
                borderBottom: `8px solid ${colors.grey2}`,
                left: '65%'
            }
        }
    })

    const [UserToggle, setUserToggle] = useState({
        title: (
            <div style={{ display: 'flex', alignItems: 'center', borderLeft: `1px solid ${colors.white}`, borderRight: `1px solid ${colors.white}`, padding: '0 20px' }}>
                {
                    auth.user && auth.user.image ? 
                        <img style={{ ...styles.userInitial, objectFit: 'contain'}} src={auth.user.image} />
                    :
                        <div style={styles.userInitial}>
                            {auth.user && getUserInitials()}
                        </div>
                }
                <div style={{ margin: '0 0 0 10px', fontSize: '12px', lineHeight: '16px', color: colors.white, fontFamily: 'Nunito Sans', fontStyle: 'normal', fontWeight: 'bold', }}>
                    {auth.user && auth.user.username}
                </div>

                <div style={{ color: colors.white, margin: '3px 10px 5px', fontSize: '12px' }}>
                    <FaChevronDown />
                </div>
            </div>
        ),
        data: [
            {
                name: "My Account",
                action: () => match.url === '/account' ? window.location.reload() : setRedirect('/account')
            },
            {
                name: "Categories",
                action: () => match.url === '/search' ? window.location.reload() : setRedirect('/search')
            },
            {
                name: "My Orders",
                action: () => match.url === '/orders' ? window.location.reload() : setRedirect('/orders')
            },
            {
                name: "Swap Offers",
                action: () => match.url === '/offers/swap' ? window.location.reload() : setRedirect('/offers/swap')
            },
            {
                name: "My Wallet",
                action: () => match.url === '/wallet' ? window.location.reload() : setRedirect('/wallet')
            },
            {
                name: "Contact Us",
                action: () => { window.open('/contact-us') }
            },
            {
                name: "Terms and Conditions",
                action: () => { window.open('/terms-and-conditions') }
            }
        ],
        wrapperStyles: {
            placeholder: {
                cursor: 'pointer',
                padding: "15px 0",
                marginLeft: "25px",
            },
            dropdown: {
                position: 'absolute',
                minWidth: '150px',
                backgroundColor: colors.dark,
                top: '65px',
                marginLeft: '8px'
            },
            dropdownItem: null,
            toolTip: null
        }
    })

    const [HeaderConfig, setHeaderConfig] = useState({
        authHeaderButtons: [
            {
                text: {
                    color: colors.white,
                    value: "+ Upload Game",
                },
                className: "hover-primary",
                styles: {
                    height: null,
                    width: '130px',
                    margin: null,
                    fontSize: '12px',
                    backgroundColor: colors.black,
                    border: `1px solid ${colors.white}`,
                    color: colors.white
                },
                isProtected: true,
                onClick: () => {
                    // Hide side menu
                    setShowMobileSideMenu(false)
                    // Show modal
                    if (auth.user && !auth.user.mobile_verified) {
                        setRedirect("/phone-verification")
                    } else {
                        // Show modal
                        setShowUploadGameModal(true)
                    }
                    
                },
            },
        ],
    })

    const [formData, setFormData] = useState({
        search: {
            element: 'input',
            value: '',
            label: false,
            labelText: 'Search',
            prepend: {
                content: <AiOutlineSearch />,
                styles: {
                    color: colors.grey
                }
            },
            props: {
                name: 'search_input',
                type: 'text',
                placeholder: 'Find your next game',
                required: true,
                autoComplete: "off",
            },
            styles: {
                backgroundColor: '#313133',
                borderRadius: '3px',
                height: '40px',
                maxWidth: '320px',
                color: colors.grey,
                padding: '0 45px',
                fontSize: '14px',
                outline: 'none',
                border: 'none',
            },
            className: "Placeholder-white"
        },
        game: {
            element: 'input',
            value: '',
            label: true,
            labelText: 'Select a game',
            props: {
                name: 'name_of_game',
                type: 'text',
                placeholder: 'Enter name of game',
                required: true
            }
        },
    })

    const {
        headerButtons,
        headerStyles,
        isVisible = true,
    } = headerConfig


    const AttemptSignOut = async () => {
        setRedirect('/logout')
    }

    const GetNavigationTray = (headerButtons) => {
        let template = []
        const { auth } = props
        if (auth.loggedIn) {
            HeaderConfig.authHeaderButtons.map((navItem, index) => {
                template.push(<Button {...navItem} key={index} />)
            })
        } else {
            headerButtons.map((navItem, index) => {
                if (navItem.isProtected && !auth.loggedIn) {
                    return null
                } else {
                    template.push(<Button {...navItem} key={index} />)
                }
            })
        }
        return template
    }

    const AttemptGameSearch = (query, timer = null) => {
        let queryFormattedToLowerCase = String(query).toLocaleLowerCase()

        //
        const _doGameSearch = async (query) => {            
            // Search: Do Game Query
            const responseObject = await PostMan(`listing/games/?q=${queryFormattedToLowerCase}`, 'GET')

            if (responseObject.status === 'success') {
                let responseData = responseObject.data
                let listingsGameList = responseData.games
                let queryset = listingsGameList.filter(game => {
                    let gameNameFormattedToLowerCase = String(game.name).toLowerCase()
                    return gameNameFormattedToLowerCase.startsWith(queryFormattedToLowerCase)
                })
                // Stop Searching
                setSearching(false)

                return setGameSearchResult(queryset)
            }
            else { }
        }

        if (timer) {
            if (queryFormattedToLowerCase.length === 0) {
                return
            } else {
                let searching = setTimeout(async () => {
                    _doGameSearch(query)
                }, timer);
                setSearching(searching)
            }
        } else {
            if (queryFormattedToLowerCase.length === 0) {
                return window.location = "/search"
            } else {
                return window.location = `/search/${queryFormattedToLowerCase}`
            }
        }
    }

    const FetchMyNotifications = async () => {
        const responseObject = await PostMan(`notifications/`, 'GET')
        if (responseObject.status === 'success') {
            let notifications = responseObject.data
            // Sort Notifications
            let notificationsArray = RenderNotifications(notifications)
            // Set Notifications in state
            let notificationsBuffer = Notifications
            notificationsBuffer.data = notificationsArray
            await setNotifications({ ...notificationsBuffer })
        }
        else {
            //
        }
    }

    const FetchWallet = async () => {
        const responseObject = await PostMan(`wallet/`, 'GET')
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            let wallet = responseData.wallet
            // Update Wallet in state.
            await setWallet({ ...wallet })
        }
        else { }
    }    

    const GoToSearchResult = (game) => {
        // 
        window.location = `/search/${game.slug}`
    }

    const pageViewsTracking = () => {
        const pathname = match.path
        let pageView
        if (pathname === "*") pageView = '/not-found';
        else pageView = pathname
        // Send GA page view metrics
        gtag('config', 'G-JHBNQFN27M', {
            page_title: document.title,
            page_path: pageView
        });
    }

    useEffect(() => {
        // Google Analytics Page Tracking
        pageViewsTracking()

        // Run only if logged in
        if (auth && auth.loggedIn) {
            // Fetch Notifications
            FetchMyNotifications()

            // Fetch Wallet
            FetchWallet()
        }
    }, [])

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <div style={{ ...styles.container, backgroundColor: headerStyles && headerStyles.backgroundColor ? headerStyles.backgroundColor : 'transparent' }}>

            {
                ShowUploadGameModal ? (
                    <ModalGameUpload hideModal={() => setShowUploadGameModal(false)} />
                ) : null
            }

            {
                ShowModalConfirmOrder ? (
                    <ModalConfirmOrder 
                        orderPayload={[]}
                        hideModal={() => setShowModalConfirmOrder(false)}
                    />
                ) : null
            }

            <div className="container" style={styles.navbar}>
                {/* <Link to="/">
                    <img
                        src={require('../../../../assets/icons/logo.svg')}
                        style={styles.logo}
                    />
                </Link> */}

                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Link to="/">
                        <img
                            src={require('../../../../assets/icons/logo.svg')}
                            style={styles.logo}
                        />
                    </Link>

                    <img
                        src={require('../../../../assets/icons/beta.png')}
                        style={{ height: '20px', margin: '0 5px' }}
                    />
                </div>
                
                <IsDesktop>
                    {
                        auth.loggedIn && isVisible ? (
                            <div>
                                <SearchableInput
                                    formData={formData}
                                    change={(newFormData) => {
                                        setFormData({ ...newFormData })
                                        // Stop Search Attempt
                                        if (Searching) {
                                            clearTimeout(Searching)
                                            setSearching(false)
                                        }
                                        // Filter Game List
                                        AttemptGameSearch(newFormData.search.value, 1200)
                                    }}
                                    keyUpHandler={() => {
                                        if (Searching || GameSearchResult.length > 0) {
                                            return
                                        } else {
                                            AttemptGameSearch(formData.search.value)
                                        }
                                    }}
                                    field={{
                                        id: 'search',
                                        config: formData.search
                                    }}

                                    filteredList={GameSearchResult}
                                    setFilteredList={setGameSearchResult}
                                    selected={SelectedGameListing}
                                    setSelectedObject={(game) => GoToSearchResult(game)}
                                />
                            </div>

                        ) : null

                    }

                    <div style={styles.navigation}>
                        {

                            isVisible ? (
                                GetNavigationTray(headerButtons)
                            ) : null

                        }
                    </div>
                    
                </IsDesktop>
                
                {
                    auth.loggedIn ? (
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            {/* {
                                isVisible && <Dropdown {...Notifications} />
                            } */}
                            
                            <IsDesktop>
                                {
                                    isVisible ? (
                                        <>
                                            <Dropdown {...UserToggle} />

                                            <div className={"hover-primary"}
                                                onClick={() => match.url === '/wallet' ? window.location.reload() : setRedirect('/wallet')}
                                                style={{
                                                    border: '1px solid #7F3F98',
                                                    margin: '0 25px',
                                                    padding: '0 10px',
                                                    color: colors.success,
                                                    fontSize: '12px',
                                                    lineHeight: '16px',
                                                    boxSizing: 'border-box',
                                                    borderRadius: '3px'
                                                }}
                                            >
                                                ₦{Wallet && Wallet.balance}
                                            </div>
                                        </>
                                    ) : null
                                }

                                <FaPowerOff
                                    style={{ margin: '0 0 0 15px', cursor: 'pointer' }}
                                    color={colors.primary}
                                    onClick={() => AttemptSignOut()}
                                    size={20}
                                />
                            </IsDesktop>

                            {/* <Breakpoint name="notDesktop">
                                <GiHamburgerMenu
                                    style={{ margin: '0 0 0 15px', cursor: 'pointer' }}
                                    color={colors.primary}
                                    onClick={() => setShowMobileSideMenu(!ShowMobileSideMenu)}
                                    size={20}
                                />
                            </Breakpoint> */}

                        </div>
                    ) : null
                }

                <Breakpoint name="notDesktop">
                    <GiHamburgerMenu
                        style={{ margin: '0 0 0 15px', cursor: 'pointer' }}
                        color={colors.primary}
                        onClick={() => setShowMobileSideMenu(!ShowMobileSideMenu)}
                        size={20}
                    />
                </Breakpoint>

            </div>


            {
                ShowMobileSideMenu ? (
                    <Breakpoint name="notDesktop">
                        <ModalOverlay>
                            <div className={"no-scrollbar"}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    backgroundColor: colors.black,
                                    position: 'fixed',
                                    height: '100%',
                                    top: 0, left: 0,
                                    minWidth: '300px',
                                    overflowY: 'scroll'
                                }}
                            >
                                <div>
                                    <div style={{ position: 'fixed', width: '100%', backgroundColor: colors.black }}>
                                        <div style={{ 
                                            padding: '20px 20px',
                                            display: 'flex', justifyContent: 'space-between'
                                        }}>
                                            <Link to="/">
                                                <img height={75}
                                                    src={require('../../../../assets/icons/logo.svg')}
                                                    style={styles.logo}
                                                />
                                            </Link>

                                            <FaTimes onClick={() => setShowMobileSideMenu(!ShowMobileSideMenu)} size={20} color={colors.white} />
                                        </div>
                                    </div>

                                    <div style={{ margin: '50px 0', padding: '20px 20px', }}>
                                        {
                                            auth.loggedIn && isVisible ? (
                                                <div>

                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', margin: '30px 0' }}>
                                                            {
                                                                auth.user && auth.user.image ?
                                                                    <img style={{ ...styles.userInitial, objectFit: 'contain', height: '40px', width: '40px', }} src={auth.user.image} />
                                                                    :
                                                                    <div style={{ ...styles.userInitial, height: '40px', width: '40px', }}>
                                                                        {auth.user && getUserInitials()}
                                                                    </div>
                                                            }
                                                            <div style={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: '18px', color: colors.white, fontFamily: 'Nunito Sans', fontStyle: 'normal', fontWeight: 'bold', }}>
                                                                {auth.user && auth.user.username}
                                                            </div>
                                                        </div>

                                                        <div className={"hover-primary"}
                                                            onClick={() => match.url === '/wallet' ? window.location.reload() : setRedirect('/wallet')}
                                                            style={{
                                                                border: '1px solid #7F3F98',
                                                                padding: '0 10px',
                                                                color: colors.success,
                                                                fontSize: '12px',
                                                                lineHeight: '16px',
                                                                boxSizing: 'border-box',
                                                                borderRadius: '3px'
                                                            }}
                                                        >
                                                            ₦{Wallet && Wallet.balance}
                                                        </div>
                                                    </div>


                                                    <SearchableInput
                                                        formData={formData}
                                                        change={(newFormData) => {
                                                            setFormData({ ...newFormData })
                                                            // Stop Search Attempt
                                                            if (Searching) {
                                                                clearTimeout(Searching)
                                                                setSearching(false)
                                                            }
                                                            // Filter Game List
                                                            AttemptGameSearch(newFormData.search.value, 1200)
                                                        }}
                                                        keyUpHandler={() => {
                                                            if (Searching || GameSearchResult.length > 0) {
                                                                return
                                                            } else {
                                                                AttemptGameSearch(formData.search.value)
                                                            }
                                                        }}
                                                        field={{
                                                            id: 'search',
                                                            config: formData.search
                                                        }}

                                                        filteredList={GameSearchResult}
                                                        setFilteredList={setGameSearchResult}
                                                        selected={SelectedGameListing}
                                                        setSelectedObject={(game) => GoToSearchResult(game)}
                                                    />

                                                    <div style={{ margin: '30px 0' }}>
                                                        {
                                                            UserToggle.data.map((menuItem, index) => {
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className={'hover-primary'}
                                                                        onClick={() => {
                                                                            // Close Menu
                                                                            setShowMobileSideMenu(false)
                                                                            // Run action
                                                                            menuItem.action()
                                                                        }}
                                                                        style={{
                                                                            color: colors.white,
                                                                            cursor: 'pointer',
                                                                            padding: '15px 0',
                                                                            borderBottom: `2px solid ${colors.primary}`
                                                                        }}
                                                                    >
                                                                        {menuItem.name}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>


                                                    <div style={{
                                                        ...styles.navigation,
                                                        justifyContent: 'center',
                                                        margin: '15px 0'
                                                    }}>
                                                        {

                                                            isVisible ? (
                                                                GetNavigationTray(headerButtons)
                                                            ) : null

                                                        }
                                                    </div>
                                                </div>

                                            ) : null

                                        }
                                    </div>
                                </div>

                                
                                {
                                    auth.loggedIn ? (
                                        <div onClick={() => AttemptSignOut()}
                                            style={{
                                                alignSelf: 'center',
                                                color: colors.grey,
                                                cursor: 'pointer',
                                                fontSize: '15px',
                                                margin: '50px 0',
                                            }}
                                        >
                                            Sign Out
                                            <FaPowerOff
                                                style={{ margin: '0 0 -5px 15px', }}
                                                color={colors.primary}
                                                size={20}
                                            />
                                        </div>
                                    ) : (
                                        <div onClick={() => setRedirect("/login")}
                                            style={{
                                                alignSelf: 'center',
                                                color: colors.grey,
                                                cursor: 'pointer',
                                                fontSize: '15px',
                                                margin: '50px 0',
                                            }}
                                        >
                                            Sign In
                                            <FaSignInAlt
                                                style={{ margin: '0 0 -5px 15px', }}
                                                color={colors.primary}
                                                size={20}
                                            />
                                        </div>
                                    )
                                }
                                

                            </div>
                        </ModalOverlay>
                    </Breakpoint>
                ) : null
            }
        </div>
    )    
}


const styles = {
    container: {
        height: "69px",
        width: '100%',
        position: 'fixed',       
        display: 'flex',
        alignItems: 'center',
        background: colors.black,
        zIndex: 99
    },
    navbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        height: '35px'
    },
    navigation: {
        display: 'flex',
        alignItems: 'center',
    },
    userInitial: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.white,
        color: colors.primary,
        height: '32px',
        width: '32px',
        borderRadius: '50%',
        fontSize: '15px',
        lineHeight: '20px',

        cursor: 'pointer'
    }
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        logout
    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))
