// import React, { useEffect } from 'react'
import { dojahAppID, dojahPublicKey } from '../../App.json';

export function GetConnectionOptions(data) {

    const {
        user,
        first_name,
        last_name,
        dob = "2022-03-12",
        serviceType = "custom", //'verification', 'identification', 'verification', 'liveness'
        onSuccess,
        onError,
        onClose,
    } = data


    const options = {
        app_id: dojahAppID,
        p_key: dojahPublicKey,
        type: serviceType,
        user_data: {
            first_name: first_name,
            last_name: last_name,
            dob: dob,
        },
        metadata: {
            user_id: user.id,  // #####
        },
        config: {
            debug: window.DOJAH_DEBUG || window.Connect.environment === 'development',
            aml: false,  // Anti-money laundering (AML) checks
            review_process: 'Automatic',
            mobile: true,
            verification: true,
            pages: [
                {
                    page: 'government-data',
                    config: {
                        bvn: true,
                        nin: true,
                        dl: true,
                    },
                },
                { page: 'selfie' },


                // { 
                //     page: 'id', 
                //     config: { 
                //         bvn: true,
                //         nin: true,
                //         dl: true,
                //         passport: false,  
                //         custom: true 
                //     } 
                // },

                // { page: 'id', config: { passport: false, dl: true, custom: true } },

                // {
                //     page: 'government-data',
                //     config: {
                //         bvn: true, nin: false, dl: true, mobile: false, otp: false, selfie: false
                //     }
                // },
                // {
                //     page: 'government-data',
                //     config: {
                //         bvn: false, nin: true, dl: true, mobile: false, otp: false, selfie: false
                //     }
                // },
                // {
                //     page: 'government-data',
                //     config: {
                //         bvn: false, nin: false, dl: false, mobile: true, otp: true, selfie: false
                //     }
                // },
                // { page: 'selfie' },
                // { page: 'id', config: { passport: false, dl: true, custom: true } },
                // { page: 'address' },
            ],
        },
        onSuccess: onSuccess(),
        
        onError: onError(),
        
        onClose: onClose(),
        
    };

    return options
}

// export default function Dojah() {

//     // const connect = new Connect(options);
//     // const button = document.querySelector('#button-connect');
//     // button.addEventListener('click', function () {
//     //     connect.setup();
//     //     connect.open();
//     // });

//     useEffect(() => {

//     })


//     return (
//         <div>Dojah</div>
//     )
// }
