import React, { useEffect, useState } from 'react'
import ModalOverlay from '../../../../utils/overlay/ModalOverlay'
import {
    colors,
    paystack_pub_key
} from '../../../../App.json'

import Button from '../../../../utils/Button';
import { PostMan } from '../../../../Helpers';

import IsDesktop from '../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../utils/breakpoints/IsPhone';

import SwapIcon from '../../../../assets/icons/swap_icon.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { usePaystackPayment } from 'react-paystack';

import mastercard_logo from '../../../../assets/icons/mastercard.png';


function OrderConfirmationModal(props) {
    const {
        auth,
        hideModal,
        orderPayload,
        orderFormData,
        listing,
    } = props
    
    const [GameStatus, setGameStatus] = useState("")
    const [Buttons, setButtons] = useState({
        confirmOrder: {
            text: {
                color: colors.white,
                value: "Confirm",
            },
            styles: {
                height: '50px',
                width: '100%',
                margin: '20px 0',
                backgroundColor: colors.primary,
                border: `1px solid ${colors.white}`,
                borderRadius: '3px',
                color: colors.white
            },
            onClick: () => {},
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
        closeModal: {
            text: {
                color: colors.primary,
                value: "close",
            },
            styles: {
                height: '30px',
                width: null,
                margin: null,
                backgroundColor: null,
                color: colors.white
            },
            onClick: () => hideModal(),
            loader: null,
        },
    })

    const CheckGameStatus = async () => {
        const listingId = orderPayload.listing
        const responseObject = await PostMan(`listing/${listingId}`, 'GET')
        // Handle Response
        if (responseObject.status === 'success') {
            let listing = responseObject.data
            console.log("listing: ", listing)
            setGameStatus(listing.status)
        }
        else {
            console.log("responseObject: ", responseObject)
        }
    }

    const PayButton = () => {
        const paymentReference = (new Date()).getTime()
        const config = {
            reference: paymentReference,
            email: auth.user.email,
            amount: getTotalFee() * 100,
            publicKey: paystack_pub_key,
        };

        const onSuccess = () => AttemptPlaceOrder(paymentReference)
        const onClose = () => { }

        const initializePayment = usePaystackPayment(config);
        // const onClick = () => initializePayment(onSuccess, onClose)
        const onClick = () => {
            if (GameStatus == "available") return initializePayment(onSuccess, onClose)
            else {
                toast.error("This item is not available")
            }
        }

        let btnColor, txtColor
        if (orderPayload._type === 'swap') {
            btnColor = colors.primaryLight
            txtColor = colors.white
        }
        else if (orderPayload._type === 'rent') {
            btnColor = colors.primary
            txtColor = colors.white
        }
        else {
            btnColor = colors.grey1
            txtColor = colors.primary
        }

        return (<Button { ...Buttons.confirmOrder }
            {...{
                text: {
                    color: txtColor,
                    value: <span>Confirm and Pay <img src={mastercard_logo} /></span>,
                },
                styles: {
                    height: '50px',
                    width: '100%',
                    margin: '20px 0',
                    backgroundColor: btnColor,
                    border: {
                        width: '1px',
                        style: 'solid',
                        color: colors.primary,
                        radius: '4px',
                    },
                    color: colors.white
                },
                onClick: () => onClick()
            }} 
             
        />)
    };

    const AttemptPlaceOrder = async (paymentReference) => {
        // Start Loader
        let newButtons = Buttons
        newButtons.confirmOrder.loader.isLoading = true
        await setButtons({ ...newButtons })
        // Update Payload with payment reference
        let payload = orderPayload
        payload['duration'] = orderPayload.duration * (7 * 24 * 60 * 60)
        payload['payment_reference'] = paymentReference
        // Response Object
        const responseObject = await PostMan(`order/`, 'POST', payload)
        // Stop Loader
        newButtons.confirmOrder.loader.isLoading = false
        await setButtons({ ...newButtons })
        // Handle Response
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            // Show Success Message before Redirect
            toast.success("Order has been placed successfully")
            setTimeout(()=>{
                hideModal()
                return window.location = "/orders"
                // return window.location = "/my-orders"
            }, 3000)
        }
        else {
            console.log("responseObject: ", responseObject)
        }
    }

    const getDeliveryFee = () => {
        console.log("orderPayload: ", orderPayload)
        if (orderPayload.duration && orderPayload.duration > 0) {
            return parseFloat(3200.00)
        }
        return parseFloat(1500.00)
    }

    const getServiceFee = () => {
        return parseFloat(400.00)
    }

    const getTotalFee = () => {
        const delivery_fee = getDeliveryFee()
        const service_fee = getServiceFee()
        const totalFees = service_fee + delivery_fee
        if (auth.user && auth.user.id == orderPayload.bill_to) {
            return totalFees + parseFloat(orderPayload.fee)
        } else {
            return totalFees
        }
    }


    useEffect(() => {
        // Check listing status
        CheckGameStatus()

    }, [])


    const MainContent = () => {
        return (
            <>
                <div style={styles.header} className="text-center">Confirm Order</div>

                <span className="hover-danger" style={{ display: 'flex', justifyContent: 'flex-end', margin: "0 0 15px" }}>
                    <Button {...Buttons.closeModal} />
                </span>

                <div className="row">
                    <div className="col-12">
                        <div style={{ ...styles.heading, }}>
                            Confirm your
                        </div>

                        {
                            orderPayload._type === 'buy' ? (
                                <div style={{ ...styles.heading, color: colors.primary, textTransform: 'capitalize' }}>
                                    Purchase
                                </div>
                            ) : (
                                <div>
                                    <div style={{ ...styles.heading, }}>
                                        Order for
                                    </div>

                                    <div style={{ ...styles.heading, color: colors.primary, textTransform: 'capitalize' }}>
                                        {orderPayload._type}
                                    </div>
                                </div>
                            )
                        }

                    </div>


                    <div className="col-12 my-4">
                        {/* {
                            orderPayload._type === 'swap' ? (
                                <>
                                    <div style={styles.orderFeesWrapper}>
                                        <span>Swap Items</span>
                                        <span style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            fontFamily: 'Roboto',
                                            fontSize: '14px',
                                        }}>
                                            <span style={{
                                                backgroundColor: colors.primary,
                                                color: colors.white,
                                                padding: "5px 15px",
                                                margin: "3px 0"
                                            }}>
                                                {listing.game.name} | {listing.console.short_name}
                                            </span>

                                            <img src={SwapIcon} style={{
                                                height: '20px',
                                                margin: '10px',
                                                alignSelf: 'center',
                                                transform: "rotate(90deg)",
                                            }} />

                                            {
                                                orderFormData.swap_items.value.map(swap_item => {
                                                    return (
                                                        <span style={{
                                                            backgroundColor: colors.primary,
                                                            color: colors.white,
                                                            padding: "5px 15px",
                                                            margin: "3px 0"
                                                        }}>
                                                            {swap_item.game.name} | {swap_item.console.short_name}
                                                        </span>
                                                    )
                                                })
                                            }
                                        </span>
                                    </div>

                                    <div style={styles.orderFeesWrapper}>
                                        <span>Additional Fee</span>
                                        <span style={styles.orderFees}>
                                            ₦{orderPayload.fee}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <div style={styles.orderFeesWrapper}>
                                    <span>Order Fee</span>
                                    <span style={styles.orderFees}>
                                        ₦{orderPayload.fee}
                                    </span>
                                </div>
                            )
                        } */}

                        {/* <div style={styles.orderFeesWrapper}>
                            <span>Order Fee</span>
                            <span style={styles.orderFees}>
                                ₦{orderPayload.fee}
                            </span>
                        </div>

                        <div style={styles.orderFeesWrapper}>
                            <span>Delivery Fee</span>
                            <span style={styles.orderFees}>
                                ₦{orderPayload.delivery_fee}
                            </span>
                        </div> */}


                        <div style={{ ...styles.orderFeesWrapper, padding: '15px 15px 5px', fontWeight: 600, }}>
                            <span>Charges</span>
                        </div>

                        {
                            auth.user && auth.user.id == orderPayload.bill_to ? (
                                <div className="mt-2" style={styles.orderFeesWrapper}>
                                    <span>Order Item(s) Fee</span>
                                    <span style={styles.orderFees}>
                                        ₦{parseFloat(orderPayload.fee)}
                                    </span>
                                </div>
                            ) : null
                        }

                        <div style={styles.orderFeesWrapper}>
                            <span>Delivery Fee</span>
                            <span style={styles.orderFees}>
                                {
                                    orderPayload.duration && orderPayload.duration > 0 && "Return delivery inc. "
                                }
                                ₦{getDeliveryFee()}
                            </span>
                        </div>

                        <div style={styles.orderFeesWrapper}>
                            <span>Service Fee</span>
                            <span style={styles.orderFees}>
                                ₦{getServiceFee()}
                            </span>
                        </div>




                        <div style={{ ...styles.orderFeesWrapper, padding: '15px 15px', fontWeight: 600, }}>
                            <span>Total Charges</span>
                            <span style={styles.orderFees}>
                                ₦{getTotalFee()}
                            </span>
                        </div>

                        {
                            orderPayload._type !== 'buy' ? (
                                <div style={styles.durationsWrapper}>
                                    <span>Duration</span>
                                    <span style={styles.duration}>
                                        {orderPayload.duration} weeks
                                    </span>
                                </div>
                            ) : null
                        }

                    </div>

                    <div className="col-12 mt-3">
                        <p style={styles.paragrapgh}>
                            All games sent to Velcro
                            would be tested at company HQ before being delivered. Any faulty games would be returned to the
                            Owner and will be counted as a violation against the company’s code of conduct, After a third
                            strike the user will be barred from using the site and their account suspended permanently.
                        </p>

                        <PayButton />

                        <p style={styles.paragrapgh}>
                            By clicking ‘’Confirm’’ you confirm that you have read and agreed to our terms of use and privacy policy
                        </p>
                    </div>

                </div>
            </>
        )
    }


    return (
        <ModalOverlay>
            <div style={styles.wrapper}>

                <IsDesktop>
                    <div style={styles.container}>
                        {
                            MainContent()
                        }
                    </div>
                </IsDesktop>

                <IsTablet>
                    <div style={styles.container}>
                        {
                            MainContent()
                        }
                    </div>
                </IsTablet>

                <IsPhone>
                    <div style={{
                        ...styles.container,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '-webkit-fill-available',
                        minHeight: '-webkit-fill-available',
                    }}>
                        {
                            MainContent()
                        }
                    </div>
                </IsPhone>
                
            </div>

            <ToastContainer />

        </ModalOverlay>
    )
}


const styles = {
    wrapper: { // Centered Content
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        width: "100%",
        height: "100%",
        position: 'relative',
    },

    container: {
        backgroundColor: colors.white,
        width: "450px",
        padding: "20px 30px"
    },
    header: {
        fontFamily: "Nunito Sans",
        fontWeight: 600,
        fontSize: "17px",
        lineHeight: "24px",
        color: colors.black,
        margin: "20px"
    },
    heading: {
        fontFamily: "Roboto",
        fontWeight: 600,
        fontSize: "30px",
        // lineHeight: "35px",
        color: colors.black,
    },
    paragrapgh: {
        fontSize: "14px",
        textAlign: "justify"
    },
    orderFeesWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'Nunito Sans',
        padding: '0 15px'
    },
    orderFees: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: colors.primary
    },
    durationsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'Nunito Sans',
        padding: '20px 65px',

        fontSize: '25px',
        fontWeight: 600,
    },
    duration: {
        fontFamily: 'Roboto',
        // fontSize: '25px',
        // fontWeight: 600,
        color: colors.primary
    }
}



const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationModal)