import React, { useState } from 'react';
import Header from '../../../components/main/Header';
import {
    colors
} from '../../../../../App.json'
import Button from '../../../../../utils/Button';
import LeftPanel from '../../../components/auth/LeftPanel';

import Breakpoint from '../../../../../utils/breakpoints/Base';
import IsDesktop from '../../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../../utils/breakpoints/IsPhone';


export default function PasswordResetSent(props) {

    const [HeaderConfig, setHeaderConfig] = useState({ isVisible: false })

    const [PageButtons, setPageButtons] = useState({
        next: {
            text: {
                color: colors.white,
                value: "Next",
            },
            styles: {
                width: '100%',
                margin: '20px 0 10px 0',
                backgroundColor: colors.primary,
                color: colors.white
            },
            linkTo: "/password-reset",
        },
        resendLink: {
            text: {
                color: colors.primary,
                value: "Resend Link",
            },
            styles: {
                margin: '10px 0 10px 0',
                color: colors.primary
            },
            onClick: () => window.location.reload(),
        },
    })


    const MainContent = (config) => {

        return (
            <div style={{ ...styles.panelRight, padding: `${config.containerPadding}`, }}>

                <div
                    className="d-flex justify-content-center"
                    style={{ ...styles.heading, fontSize: `${config.headingSize}` }}
                >
                    Check Your Mail
                </div>

                <div className="d-flex flex-column align-items-center justify-content-center" style={styles.subHeading} >
                    <span>A Link has been sent to your Email address.</span>
                    <span>Follow it to reset your password</span>
                </div>

                <div className="d-flex justify-content-center" style={{}}>
                    <img src={require('../../../../../assets/icons/done.png')} />
                </div>

                <div style={{ marginTop: '35px', minWidth: `${config.formMinWidth}` }}>
                    <form>

                        <Button {...PageButtons.next} />
                        <Button {...PageButtons.resendLink} />

                    </form>
                </div>

                <Breakpoint name="notPhone">
                    <div style={{ ...styles.dottedSquare }}>
                        <img src={require('../../../../../assets/icons/dotted-square-colored.png')} />
                    </div>
                </Breakpoint>
            </div>
        )
    }

    return (
        <div>
            <Header {...props} headerConfig={HeaderConfig} />

            <IsDesktop>
                <div style={styles.container.desktop}>
                    <LeftPanel />

                    <div style={{ padding: '0 50px', height: '100%' }}>
                        {
                            MainContent({
                                formMinWidth: '450px',
                                headingSize: '34px',
                                containerPadding: '200px 50px 0',
                            })
                        }
                    </div>

                </div>
            </IsDesktop>

            <IsTablet>
                <div style={styles.container.tablet}>
                    {
                        MainContent({
                            formMinWidth: null,
                            headingSize: '24px',
                            containerPadding: '150px 50px 0',
                        })
                    }
                </div>
            </IsTablet>

            <IsPhone>
                <div style={styles.container.phone}>
                    {
                        MainContent({
                            formMinWidth: '200px',
                            headingSize: '20px',
                            containerPadding: '100px 50px 0',
                        })
                    }
                </div>
            </IsPhone>

        </div>
    )
}



const styles = {
    container: {
        desktop: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
        },
        tablet: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 50px',
        },
        phone: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    panelRight: {
        // padding: '200px 50px 0',
        height: '100%',
    },
    heading: {
        // fontFamily: 'Raleway',
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28px',
        color: colors.black,
    },
    subHeading: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        // lineHeight: '24px',
        color: 'rgba(45, 58, 48, 0.5)',

        margin: '20px 0'
    },
    formLabel: {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
    },
    dottedSquare: {
        position: 'fixed',
        right: '50px',
        bottom: '50px',
    },
}