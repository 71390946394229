import React, { useState, useEffect } from 'react';

import {
    PostMan
} from '../../../../../Helpers';

import ListingCard from '../../../components/main/ListingCard';

import IsDesktop from '../../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../../utils/breakpoints/IsPhone';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


export default function FeaturedListings() {
    const [FeaturedListing, setFeaturedListing] = useState(null)

    const FetchFeaturedListings = async () => {
        const responseObject = await PostMan(`listing/featured/`, 'GET')
        if (responseObject.status === 'success') {
            let categoryGames = responseObject.data
            // Save Games to state
            await setFeaturedListing(categoryGames)
        }

        else { }
    }

    const MainContent = (config) => {
        return (
            <Carousel
                autoPlay={true}
                showThumbs={false}
                swipeable={true}
                stopOnHover={true}
                infiniteLoop={true}
                interval={2500}
                showArrows={true}
                showStatus={false}
                showIndicators={false}
                {...config.carousel}
            >
                {
                    FeaturedListing && FeaturedListing.map((game, index) => {
                        return (
                            <div key={index} style={{ width: "95%" }}>
                                <ListingCard self={game} />
                            </div>
                        )
                    })
                }
            </Carousel>
        )
    }

    useEffect(() => {
        // Fetch Category Games
        FetchFeaturedListings()
    }, [])

    return (
        // <div style={{ padding: '50px 0' }}>
        <div style={{}}>
            <div style={{
                fontSize: "25px",
                fontWeight: 600
            }}>
                Featured Listings
            </div>

            <IsDesktop>
                <div className='row'>
                    {
                        FeaturedListing && FeaturedListing.map((game, index) => {
                            return (
                                <div key={index} className="col-12 col-md-6 col-lg-3 my-3">
                                    <ListingCard
                                        self={game}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </IsDesktop>

            <IsTablet>
                {
                    MainContent({
                        carousel: {
                            centerSlidePercentage: 50,
                            centerMode: true
                        }
                    })
                }
            </IsTablet>

            <IsPhone>
                {
                    MainContent({
                        carousel: {
                            centerSlidePercentage: 75,
                            centerMode: true
                        }
                    })
                }
            </IsPhone>
            
        </div>
    )
}
