import React, { useEffect, useState } from 'react'

import { PostMan } from '../../../../Helpers';
import { colors, paystack_pub_key } from '../../../../App.json'

import BlankImage from '../../../../assets/images/game-0.png'
import Button from '../../../../utils/Button'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { usePaystackPayment } from 'react-paystack';

import GameCard from './GameCard'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FaCheck, FaShippingFast, FaRegClock, FaCross } from 'react-icons/fa'


function OrderCard(props) {
    const {
        auth,
        self
    } = props

    let listing = self.listing

    const [Buttons, setButtons] = useState({
        payNow: {
            text: {
                color: colors.white,
                value: "Pay Now",
            },
            styles: {
                height: '30px',
                width: '100%',
                margin: null,
                fontSize: "13px",
                backgroundColor: colors.success,
                color: colors.white
            },
            onClick: () => {},
            loader: {
                isLoading: false,
                size: 15,
                color: colors.white,
            },
        },
    })

    const AttemptPlaceOrder = async (paymentReference) => {
        // Start Loader
        let newButtons = Buttons
        newButtons.payNow.loader.isLoading = true
        await setButtons({ ...newButtons })
        // Set Payload
        let payload = { payment_reference: paymentReference }
        // Response Object
        const responseObject = await PostMan(`order/${self.id}/`, 'PATCH', payload)
        // Stop Loader
        newButtons.payNow.loader.isLoading = false
        await setButtons({ ...newButtons })
        // Handle Response
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            // Show Success Message before Redirect
            toast.success("Order has been placed successfully")
            setTimeout(() => {
                return window.location = "/my-orders"
            }, 3000)
        }
        else {
            console.log("responseObject: ", responseObject)
        }
    }    

    const PayButton = () => {
        const paymentReference = (new Date()).getTime()
        const config = {
            reference: paymentReference,
            email: auth.user.email,
            amount: self.fee * 100,
            publicKey: paystack_pub_key,
        };

        const onSuccess = () => AttemptPlaceOrder(paymentReference)
        const onClose = () => { }

        const initializePayment = usePaystackPayment(config);
        const onClick = () => initializePayment(onSuccess, onClose)

        return (<Button 
            {...Buttons.payNow}
            {...{
                onClick: () => onClick()
            }}
        />)
    };

    const getTimeLeft = () => {
        const today = Date.now()
        const expires_at = new Date(self.has_expired.expires_at)
        if (expires_at >= today) {
            const timeLeft = expires_at - today
            return timeLeft
            // const daysLeft = timeLeft / (1000 * 60 * 60 * 24)
            // const timeElapsedRatio = (lifespan - timeLeft) / lifespan * 100
            // return daysLeft, timeElapsedRatio
        }
        return 0
    }

    const getDaysLeft = () => {
        const timeLeft = getTimeLeft()
        if (timeLeft > 0) {
            const daysLeft = timeLeft / (1000 * 60 * 60 * 24)
            return parseInt(daysLeft)
        }
        return 0
    }


    const timeElapsedRatio = () => {
        const timeLeft = getTimeLeft()
        const expires_at = new Date(self.has_expired.expires_at)
        const delivery_date = new Date(self.delivery_date)
        if (timeLeft > 0) {
            const lifespan = expires_at - delivery_date
            const timeElapsedRatio = (lifespan - timeLeft) / lifespan * 100
            return timeElapsedRatio
        }
        return 0
    }

    // useEffect(()=>{
    //     timeElapsedRatio()
    // }, [])

    return (
        <GameCard self={listing.game} listing={listing}>
            <ToastContainer />

            {
                self.is_paid && (
                    <>
                        {
                            self.status.value == "paid" && (
                                <div style={{
                                    backgroundColor: colors.grey1, margin: '10px 0'
                                }}>
                                    <div style={{ padding: '5px', fontSize: '11px', color: colors.white }}>
                                        <span style={{ margin: '0 5px' }}>
                                            <FaRegClock color={colors.white} size={12} />
                                        </span>
                                        Your {self._type} order is being processed.
                                    </div>
                                </div>
                            ) 
                            ||
                            self.status.value === "pick_up_in_progress" && (
                                <div style={{
                                    backgroundColor: colors.warning, margin: '10px 0'
                                }}>
                                    <div style={{ padding: '5px', fontSize: '11px', color: colors.white }}>
                                        <span style={{ margin: '0 5px' }}>
                                            <FaRegClock color={colors.white} size={12} />
                                        </span>
                                        Your {self._type} order is being processed.
                                    </div>
                                </div>
                            )
                            ||
                            self.status.value === "test_in_progress" && (
                                <div style={{
                                    backgroundColor: colors.warning, margin: '10px 0'
                                }}>
                                    <div style={{ padding: '5px', fontSize: '11px', color: colors.white }}>
                                        <span style={{ margin: '0 5px' }}>
                                            <FaRegClock color={colors.white} size={12} />
                                        </span>
                                        Your {self._type} order is undergoing Tests.
                                    </div>
                                </div>
                            )
                            ||
                            self.status.value === "test_failed" && (
                                <div style={{
                                    backgroundColor: colors.danger, margin: '10px 0'
                                }}>
                                    <div style={{ padding: '5px', fontSize: '11px', color: colors.white }}>
                                        <span style={{ margin: '0 5px' }}>
                                            <FaCross color={colors.white} size={12} />
                                        </span>
                                        Your {self._type} order has been cancelled.
                                    </div>
                                </div>
                            )
                            || 
                            self.status.value === "in_transit_delivery" && (
                                <div style={{
                                    backgroundColor: colors.primaryLight, margin: '10px 0'
                                }}>
                                    <div style={{ padding: '5px', fontSize: '11px', color: colors.white }}>
                                        <span style={{ margin: '0 5px' }}>
                                            <FaShippingFast color={colors.white} size={11} />
                                        </span>
                                        Your {self._type} order is now in transit.
                                    </div>
                                </div>
                            )
                            ||
                            self.status.value === "in_progress" && (
                                <>
                                    <div style={{
                                            backgroundColor: colors.grey1, 
                                            padding: '2px',
                                            position: 'relative'
                                        }}>
                                        <div
                                            style={{
                                                height: '15px',
                                                width: `${timeElapsedRatio()}%`,
                                                backgroundColor: colors.primary,
                                            }}
                                        />
                                    </div>

                                    <div style={{ padding: '5px 0', fontSize: '11px', color: colors.grey3 }}>
                                        <span style={{ margin: '0 5px' }}>
                                            <FaRegClock color={colors.primary} size={12} />
                                        </span>
                                        Order is in your possession. You have {getDaysLeft()} days left.
                                    </div>
                                </>
                            )
                            ||
                            self.status.value === "completed" && (
                                <div style={{ margin: '10px 0' }}>
                                    <div style={{
                                        backgroundColor: colors.success, margin: '10px 0'
                                    }}>
                                        <div style={{ padding: '5px', fontSize: '11px', color: colors.white }}>
                                            <span style={{ margin: '0 5px' }}>
                                                <FaCheck color={colors.white} size={11} />
                                            </span>
                                            Your {self._type} order is completed.
                                        </div>
                                    </div>

                                    {/* {
                                        (self._type === "buy" || self._type === "swap" && self.duration == 0) && (
                                            <div style={{
                                                backgroundColor: colors.success, margin: '10px 0'
                                            }}>
                                                <div style={{ padding: '5px', fontSize: '11px', color: colors.white }}>
                                                    <span style={{ margin: '0 5px' }}>
                                                        <FaCheck color={colors.white} size={11} />
                                                    </span>
                                                    Your {self._type} order is completed.
                                                </div>
                                            </div>
                                        )
                                    } */}
                                </div>
                            )
                            ||
                            null
                        }
                    </>
                )
                ||
                self.status.value === "pending" && (
                    <div style={{
                        backgroundColor: colors.grey1, margin: '10px 0'
                    }}>
                        <div style={{ padding: '5px', fontSize: '10px', color: colors.grey3 }}>
                            Payment required to finalize {self._type} order
                        </div>
                    </div>
                )
                ||
                null
            }
            

            {
                !self.is_paid ? (
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            {
                                self.payment_status === "pending" ? <PayButton /> : null
                            }
                        </div>
                    </div>
                ) : null
            }


        </GameCard>
    )

}


const styles = {
    wrapper: {
        position: 'absolute',
        padding: '10px 15px',
        bottom: 0,
        width: '100%',
        backgroundColor: colors.white,
        borderRadius: "10px",
    },
    title: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        borderBottom: "1px solid black",
        padding: "0",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    attrib: {
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        lineHeight: "15px",

    },
    gameStatus: {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center"
        },
        heading: {
            fontFamily: "Nunito Sans",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "8px",
            lineHeight: "8px",
        },
        content: {
            fontFamily: "Nunito Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "10px",
            lineHeight: "12px",
            textTransform: "capitalize"
        }
    }
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCard)