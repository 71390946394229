import React, { useState, useEffect } from 'react'
import {
    Redirect,
    useRouteMatch,
    useLocation
} from 'react-router-dom';
import {
    colors
} from '../../../../App.json'
import { PostMan } from '../../../../Helpers';
import {
    updateUser
} from '../../../../redux/actions/AuthActions'

import Header from '../../components/main/Header';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import IsDesktop from '../../../../utils/breakpoints/IsDesktop';
import IsTablet from '../../../../utils/breakpoints/IsTablet';
import IsPhone from '../../../../utils/breakpoints/IsPhone';

import HashLoader from "react-spinners/HashLoader"


function EmailVerificationLinkHandler(props) {
    const {
        auth,
        updateUser
    } = props
    const match = useRouteMatch()
    const location = useLocation()

    const { state } = location

    const [redirect, setRedirect] = useState(null)

    const [HeaderConfig, setHeaderConfig] = useState({ isVisible: false })

    const AttemptConfirmEmailAddress = async (verificationKey) => {
        let payload = {
            key: verificationKey,
        }
        const responseObject = await PostMan('account/verification/email/', 'post', payload, true)
        //
        let responseData = responseObject.data
        if (responseObject.status === 'success') {
            // Toast Success Message
            toast.success(responseData.message)
            // Update user details
            updateUser(responseData.user)
            //
            setTimeout(async () => {
                //
                return setRedirect("/email-confirmation/success")
            }, 1500)
        }
        else {
            // Toast Error Message
            toast.error(responseData.message)
            //
            setTimeout(() => {
                setRedirect("/email-confirmation")
            }, 3500)
        }

    }

    const MainContent = (config) => {

        return (
            <div style={{ ...styles.panelRight, padding: "200px 50px 0", }}>

                <div
                    className="d-flex justify-content-center"
                    style={{ ...styles.heading, fontSize: `${config.headingSize}` }}
                >
                    Email Verification in Progress
                </div>

                <div className="d-flex justify-content-center" style={{ margin: '100px' }}>
                    <HashLoader
                        color={colors.primary}
                        loading={true}
                        size={150}
                    />
                </div>
            </div>
        )
    }

    useEffect(() => {
        //
        if (state && state.key) {
            if (!auth.user) {
                setRedirect({
                    pathname: '/login',
                    state: {
                        nextUrl: {
                            pathname: '/email-confirmation/verifying',
                            state: { key: state.key }
                        }
                    }
                })
            } else {
                AttemptConfirmEmailAddress(state.key)
            }
        } else {
            setRedirect("/")
        }
    }, [])

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <>
            <IsDesktop>
                <div style={styles.container.desktop}>
                    <div style={{ padding: '0 50px', height: '100%' }}>
                        {
                            MainContent({
                                formMinWidth: '450px',
                                headingSize: '34px',
                                containerPadding: '200px 50px 0',
                            })
                        }
                    </div>

                </div>
            </IsDesktop>

            <IsTablet>
                <div style={styles.container.tablet}>
                    {
                        MainContent({
                            formMinWidth: null,
                            headingSize: '24px',
                            containerPadding: '150px 50px 0',
                        })
                    }
                </div>
            </IsTablet>

            <IsPhone>
                <div style={styles.container.phone}>
                    {
                        MainContent({
                            formMinWidth: '200px',
                            headingSize: '20px',
                            containerPadding: '100px 50px 0',
                        })
                    }
                </div>
            </IsPhone>


            <ToastContainer />
        </>
    )
}


const styles = {
    container: {
        desktop: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        tablet: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 50px',
        },
        phone: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    panelRight: {
        // padding: '200px 50px 0',
        height: '100%',
    },
    heading: {
        // fontFamily: 'Raleway',
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28px',
        color: colors.black,
    },
    subHeading: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        // lineHeight: '24px',
        color: 'rgba(45, 58, 48, 0.5)',

        margin: '20px 0'
    },
    formLabel: {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
    },
    dottedSquare: {
        position: 'fixed',
        right: '50px',
        bottom: '50px',
    },
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        updateUser
    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationLinkHandler)